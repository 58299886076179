<template>
    <span class="dataPointSubLabel">{{language}}</span>
</template>
<script>

import { mapGetters, mapActions, mapMutations  } from "vuex";
export default {
    props:{
        languageId:{
            type:String,
            required:true
        }
    },
    
    data(){
        return{
            language:''
        }
    },
    computed:{
         ...mapGetters("languages", ["getLanguageCodeText", "getLanguageCode"])
    },
    mounted(){
        this.getLanguageDetail()
    },
    methods:{
         ...mapActions("languages", ["fetchLanguageCodeText"]),
        ...mapMutations("languages", ["setLanguageCode"]),
        getLanguageDetail :async function(){
             var language
            if(this.getLanguageCode != this.languageId){
                language = await this.fetchLanguageCodeText(this.languageId)
                if(language.status == 200){
                    this.language = language.data
                    this.setLanguageCode(this.languageId)
                }else{
                    this.language = this.languageId
                }
            }else{
                if(this.getLanguageCodeText.length >0 || this.getLanguageCodeText){
                    this.language =this.getLanguageCodeText
                }else{
                    language = await this.fetchLanguageCodeText(this.languageId)
                    if(language.status == 200){
                        this.language = language.data
                        this.setLanguageCode(this.languageId)
                    }else{
                        this.language = this.languageId
                    }
                }
            }
      
    },
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";
.dataPointSubLabel{
@include mds-eyebrow-heading();

}
</style>