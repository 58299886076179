<template>
  <div>
    <div v-if="!showLoader">
      <div>
        <mds-layout-grid>
          <mds-row
            :style="
              !isNavigatedFromBulkEdit
                ? 'display: grid; grid-template-columns: 20% 80%;'
                : ''
            "
            style="margin:0"
          >
            <div class="leftBlock" v-if="!isNavigatedFromBulkEdit">
              <div class="leftEntityBlock">
                  <div style="padding: 1% 16px;">
                    <!-- temporary change : added style="display:none" -->
                    <search-input
                      style="display:none"
                      v-model="filteredEntities"
                      searchPlaceHolder="Search All Entities"
                    ></search-input>
                  </div>
                  <div class="leftEntityBlockHeaderButtonDiv">
                    <mds-layout-grid style="width:100%">
                      <mds-row style="margin: 0;align-items:center;">
                        <mds-col style="padding:0;">
                          <span class="entityTitleName">ALL ENTITIES</span>
                        </mds-col>
                        <mds-col style="padding:0;">
                          <mds-button
                            v-if="!isApproveDatapoints"
                            @click="addEntityClicked()"
                            icon="plus"
                            variation="primary"
                            size="small"
                            style="float:right"
                            :disabled="!(permissions.includes('add-investment-add-new-entity') || permissions.includes('*'))"
                          >Add New
                          </mds-button>
                        </mds-col>
                      </mds-row>
                    </mds-layout-grid>
                  </div>
                  <div class="entityContainer">
                    <tree-view
                      v-for="(entities, index) in filteredEntities"
                      :key="entities.EntityId"
                      :node="entities"
                      :index="index"
                      :handle-click="entityListClicked"
                    >
                    </tree-view>
                  </div>
                  <!-- <div
                    class="leftEntityBlockHeaderButtonDiv"
                    style="margin: 5% 0;"
                  >
                    <h5 style="margin: 0">RECENT ENTITIES</h5>
                    <button-component
                      :onClick="addEntityClicked"
                      buttonName="See all"
                      buttonVariation="secondary"
                      buttonSize="small"
                      style="margin-left: auto;order: 2;"
                    >
                    </button-component>
                  </div> -->
              </div>
            </div>
            <div class="rightBlock">
              <div
                v-if="isNavigatedFromBulkEdit"
                class="breadCrumesDiv"
                style="margin:16px 0;"
              >
                <a @click="$router.go(-1)" class="breadCrumesAnchor">Entities</a
                ><span> > {{ getSelectedEntity.entityname }}</span>
              </div>
              <div
                :style="!isNavigatedFromBulkEdit ? 'margin:3% 0' : 'margin:0'"
              >
                <!--***MESSAGE*** it will display a message if no entity is selected -->
                <div
                  v-if="selectedEntity && !onEntityClickshowLoader"
                  style="margin: 10% 5%"
                >
                  <h4>Select an entity in the tree to the left.</h4>
                </div>
                <!-- /***MESSAGE*** -->

                <!--***LOADER*** displays the loader when entity is selected until data gets loaded -->
                <div
                  class="loaderCursor"
                  v-if="onEntityClickshowLoader"
                  style="width: 100%; height: 500px; position: relative"
                >
                  <loader-component></loader-component>
                </div>
                <!-- /***LOADER*** -->

                <!-- Data Point Change Area -->
                <div
                  v-if="!onEntityClickshowLoader"
                  style="width: 100%; height: 100%"
                >
                  <!-- Datapoint Keys and Values -->

                  <data-points-details
                    :key="componentKey"
                    v-if="getSelectedEntity.entityname && showDataPointArea"
                    :selectedEntityDataPointGroupDetails="getEntities"
                    :addSubNavEntityButtonName="addEntityButtonName"
                    :showSubHeader="true"
                    :sectionSize="5"
                    :isBold="true"
                    :inputsDataPointReadOnly="inputsReadOnly"
                    :isApproveDatapointsRoute="isApproveDatapoints"
                    :taskId="taskId"
                    :navigatedFromAssignedTOScreen="
                      navigatedFromAssignedTOScreen
                    "
                    @saveAsDraft="saveAsDraft"
                    @discardDraft="discardDraft"
                    @addEntityWithParentDetails="addEntityWithParentDetails"
                    @makingDataPointsEditable="makeEditAble"
                    @onLanguageChanged="changeLanguage"
                    @onAutoMappedDatapointChange="
                      onAutoMappedDatapointChangeValue
                    "
                     @editExternalIdentifiersClick="openEditExternalIdentifiers"
                  >
                  </data-points-details>

                  <!-- /Data Point change Area -->
                </div>
              </div>
            </div>
          </mds-row>
        </mds-layout-grid>
      </div>
      <!-- 
        :parentEntityDetails="selectedNodeDetails" -->
      <add-entity-with-parent-details
        v-if="openAddEntityWithParentDetails"
        @clicked="closeAddEntityWithParentDetailsModal"
        @entityAdded="newEntityAdded"
      >
      </add-entity-with-parent-details>
      <add-investment-add-entity-modal
        v-if="openModal"
        @clicked="entityModalClick"
        @entityAdded="newEntityAdded"
      ></add-investment-add-entity-modal>
    </div>
    <div class="loaderCursor" v-if="showLoader">
      <loader-component loaderSize="large"></loader-component>
    </div>
    <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="5000"
      @close="showNotification = false"
    ></notification-component>
    <dialog-component
      ref="confirmDialogue"
      :iconDetails="dialogIcon"
      :dialogMessage="dialogMessage"
    ></dialog-component>
    <auto-mapped-popup ref="confirmAutoMapDialogue"> </auto-mapped-popup>
    <!-- <footer-component v-if="!showLoader"></footer-component> -->
  </div>
</template>

<script>
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import searchInput from "../ui_component/searchInput.vue";
import { MdsButton } from "@mds/button";
import treeView from "../ui_component/treeView";
import { mapGetters, mapActions, mapMutations } from "vuex";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import DataPointsDetails from "../common_components/DataPointsDetails.vue";
import AddEntityWithParentDetails from "../common_components/AddEntityWithParentDetails.vue";
import AddInvestmentAddEntityModal from "../common_components/AddInvestmentAddEntityModal";
import {
  saveDatapointAsDraft,
  insertNewEntity,
} from "../../services/add_investment_service";
import payloadDetails from "../../config/payloadDetails.json";
import DialogComponent from "../ui_component/DialogComponent.vue";
import { dateFormatterMixin } from '../../mixins/date.js';
// import {getAutoMappingDetails} from "../../services/add_investment_service.js";

import AutoMappedPopup from "../common_components/autoMappedPopup.vue";
// import FooterComponent from "../common_components/FooterComponent";
export default {
  name: "AddInvestment",
  components: {
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    searchInput,
    MdsButton,
    treeView,
    LoaderComponent,
    NotificationComponent,
    DataPointsDetails,
    AddEntityWithParentDetails,
    AddInvestmentAddEntityModal,
    DialogComponent,
    AutoMappedPopup,
    // FooterComponent,
  },
  data() {
    return {
      isNavigatedFromBulkEdit: false,
      fetchEntitiesResponse: "",
      isApproveDatapoints: false,
      navigatedFromAssignedTOScreen: false,
      taskId: null,
      entityIdFromRoute: null,
      dataPointHistoryData: [],
      dialogComponentDisplay: true,
      dialogIcon: "",
      dialogMessage: "",
      showDataPointArea: true,
      componentKey: 0,
      notificationMessage: "",
      AddEntityParentDetailstEntities1: "",
      showNotification: false,
      notificationKey: "",
      notificationType: "",
      openModal: false,
      searchEntity: "",
      popOverToggle: false,
      triggerByDatapointId: "",
      showLoader: false,
      onEntityClickshowLoader: false,
      addEntityButtonName: "",
      selectedNodeDetails: "",
      openAddEntityWithParentDetails: false,
      inputsReadOnly: false,
      datapointEntitiesConvertedJson: [],
      sampleJson: {
        datapointgroups: [],
      },
      edittable:false,
    };
  },
  mixins: [ dateFormatterMixin ],
  computed: {
    ...mapGetters("languages", ["getLanguages", "getSelectedLanguage"]),
    ...mapGetters("entity", [
      "getEntities",
      "getSelectedEntity",
      "getEntityTypes",
    ]),
    ...mapGetters("dropDowns", [
      "getEnumDropDowns",
      "getReferenceDropDowns",
      "getEnumIdList",
      "getReferenceIdList",
    ]),
    permissions () {
      return this.$store.state.auth.permissions;
    },
    filteredEntities() {
      if (this.getEntities.length === 0) {
        return null;
      } else {
        return this.getEntities.entities.filter((entity1) => {
          return entity1.entityname
            .toLowerCase()
            .includes(this.searchEntity.toLowerCase());
        });
      }
    },
    selectedEntity() {
      if (this.getSelectedEntity != null) {
        if (this.getSelectedEntity.entityid) {
          return false;
        }
      }
      return true;
    },
  },

  props: {
    passAddInvestmentDetailsObject: {
      type: Object,
    },
  },

  async mounted() {
    this.isNavigatedFromBulkEdit = false;
    this.setSelectedEntity({}); //clear the selected entity.
    if(await this.$route.query.isEditable){
     this.edittable = await this.$route.query.isEditable.toLowerCase() === 'true';
    }
  
    if (await this.$route.query.entityid && await this.$route.query.taskId) {
      // for assign to you and home
      this.entityIdFromRoute = this.$route.query.entityid;
      this.taskId = parseInt(this.$route.query.taskId);
      this.navigatedFromAssignedTOScreen = true;
    }
    this.showLoader = true;
    this.setSelectedLanguage("en");
    if (await this.$route.query.entityid && this.edittable) {
      // for assign to you..and home
      this.inputsReadOnly = false;
      this.isApproveDatapoints = true;
      payloadDetails.payloadForGetEntites.entityid = this.entityIdFromRoute;
      payloadDetails.payloadForGetEntites.TaskId = this.taskId;
      var response = await this.fetchEntities(
        payloadDetails.payloadForGetEntites
      );
      if (response.status == 200) {
        this.addEntityButtonName = "";
        this.setSelectedEntity(response.data.entity);
        this.selectedNodeDetails = response.data.entity;
      } else {
        await this.clearEntitiesPayLoad();
        await this.fetchEntities(payloadDetails.payloadForGetEntites);
      }
    } else if (await this.$route.query.entityid && !this.edittable && await this.$route.query.isEditable !== undefined) {
      // this block is executed if user navigates from bulk edit 
      this.inputsReadOnly = false;
      this.isNavigatedFromBulkEdit = true;
      this.navigatedFromAssignedTOScreen = false;
      payloadDetails.payloadForGetEntites.entityid = await this.$route.query.entityid;
      payloadDetails.payloadForGetEntites.TaskId = 0;
      let responseDetails = await this.fetchEntities(
        payloadDetails.payloadForGetEntites
      );
      if (responseDetails.status == 200) {
        this.addEntityButtonName = "";
        this.setSelectedEntity(responseDetails.data.entity);
        this.selectedNodeDetails = responseDetails.data.entity;
      } else {
        await this.clearEntitiesPayLoad(); 
        await this.fetchEntities(payloadDetails.payloadForGetEntites);
      }
    }else if (this.$route.params.entityid) {
      // this block is executed if user navigates directly from global search bar
      this.inputsReadOnly = true;
      this.isNavigatedFromBulkEdit = false;
      this.isApproveDatapoints = false;
      this.navigatedFromAssignedTOScreen = false;
      payloadDetails.payloadForGetEntites.entityid = this.$route.params.entityid;
      payloadDetails.payloadForGetEntites.TaskId = 0;
      let responseDetails = await this.fetchEntities(
        payloadDetails.payloadForGetEntites
      );
      if(await this.$route.params.showNotification != undefined && await this.$route.params.showNotification != false){
        // this block is executed when user navigates from edit external code with success message
        this.showNotification=true
        this.notificationType="success"
        this.notificationKey="success-default"
        this.notificationMessage=this.$route.params.notificationMessage
      }
      if (responseDetails.status == 200) {
        this.addEntityButtonName = "";
        this.setSelectedEntity(responseDetails.data.entity);
        this.selectedNodeDetails = responseDetails.data.entity;
      } else {
        await this.clearEntitiesPayLoad();
        await this.fetchEntities(payloadDetails.payloadForGetEntites);
      }
    } else {
      // this is executed if user navigates to add investment for first time
      await this.clearEntitiesPayLoad();
      await this.fetchEntities(payloadDetails.payloadForGetEntites);
    }

    if (this.getEntityTypes.length == 0) {
      await this.fetchEntityTypes();
    }

    if (this.getLanguages.length == 0) {
      await this.fetchLanguageDetails();
    }
    this.showLoader = false;
  },

  watch: {
    async passAddInvestmentDetailsObject(value) {
      this.showLoader = true;
      this.inputsReadOnly = true;
      this.isApproveDatapoints = false;
      var obj = {
        validitystate: 1,
        languageid: "en",
        rootentitytypeid: 1,
        entityid: value.selectedEntityType,
        TaskId: 0,
      };

      var response = await this.fetchEntities(obj);
      if (response.status == 200) {
        this.addEntityButtonName = "";
        this.setSelectedEntity(response.data.entity);
        this.selectedNodeDetails = response.data.entity;
      }

      this.showLoader = false;
    },
  },
  methods: {
    ...mapActions("dropDowns", [
      "fetchEnumDropDownDetails",
      "fetchReferenceDropDownDetails",
    ]),
    ...mapActions("languages", ["fetchLanguageDetails"]),
    ...mapActions("entity", [
      "fetchEntities",
      "fetchEntitiesCheck",
      "fetchEntityTypes",
    ]),
    ...mapMutations("entity", ["setSelectedEntity", "SET_ENTITIES"]),
    ...mapMutations("languages", ["setSelectedLanguage"]),
    async onAutoMappedDatapointChangeValue() {
      this.componentKey++;
    },
    openEditExternalIdentifiers(){
            const { entityname, entityid} = this.getSelectedEntity
            this.$router.push(`/data-management/add-investment/edit-external-codes?entityname=${entityname}&entityid=${entityid}`);
    },
    changeTargetDatapointValue(
      datapointGroups,
      targetDataPointDetails,
      arrayindex
    ) {
      for (let i = 0; i < datapointGroups.length; i++) {
        for (let j = 0; j < datapointGroups[i].datapointslist.length; j++) {
          if (
            datapointGroups[i].datapointslist[j].datapointid ==
              targetDataPointDetails.targetdatapointid &&
            datapointGroups[i].datapointslist[j].arrayindex == arrayindex
          ) {
            datapointGroups[i].datapointslist[j].value =
              targetDataPointDetails.targetvalue;
            this.selectRefKey += 1;
            this.selectEnumKeySec += 1;
            this.selectEnumKey += 1;
            return;
          }
        }
        if (datapointGroups[i].datapointgroups.length > 0) {
          this.changeTargetDatapointValue(
            datapointGroups[i].datapointgroups,
            targetDataPointDetails,
            arrayindex
          );
        }
      }
    },
    clearEntitiesPayLoad() {
      payloadDetails.payloadForGetEntites.languageid = this.getSelectedLanguage;
      payloadDetails.payloadForGetEntites.entityid = null;
      payloadDetails.payloadForGetEntites.TaskId = 0;
    },

    async changeLanguage(value) {
      this.showLoader = true;
      this.setSelectedLanguage(value);
      payloadDetails.payloadForGetEntites.languageid = value;
      payloadDetails.payloadForGetEntites.entityid = null;
      await this.getEntityDetails(this.getSelectedEntity);
      this.showLoader = false;
    },

    async makeEditAble() {
      this.onEntityClickshowLoader = true;

      // if(this.getEnumIdList.length > 0){
      //   if(this.getEnumDropDowns.length == 0){
      //           await this.fetchEnumDropDownDetails(this.getEnumIdList)
      //         }else{
      //           var getEnums=[]
      //           for(let j=0;j<this.getEnumIdList.length;j++){
      //             var checkEnum = this.getEnumDropDowns.filter((enumdetails) => this.getEnumIdList[j]== enumdetails.enumid)
      //             if(checkEnum.length == 0){
      //                getEnums.push(this.getEnumIdList[j])
      //             }
      //             if(j==this.getEnumIdList.length-1){
      //               if(getEnums.length !=0){
      //                 await this.fetchEnumDropDownDetails(getEnums)
      //               }
      //             }
      //           }

      //         }
      // }
      // if(this.getReferenceIdList.length > 0){
      //   if(this.getReferenceDropDowns.length == 0){
      //       await this.fetchReferenceDropDownDetails(this.getReferenceIdList)
      //     }else{
      //       var getReferences=[]
      //       for(let r=0;r<this.getReferenceIdList.length;r++){
      //         var checkReference = this.getReferenceDropDowns.filter((referencedetails) => this.getReferenceIdList[r]== referencedetails.refrenceid)
      //         if(checkReference.length == 0){
      //             getReferences.push(this.getReferenceIdList[r])
      //         }
      //         if(r==this.getReferenceIdList.length-1){
      //           if(getReferences.length !=0){
      //             await this.fetchReferenceDropDownDetails(getReferences)
      //           }
      //         }
      //       }

      //     }
      // }

      this.inputsReadOnly = false;
      this.onEntityClickshowLoader = false;
    },

    entityModalClick(value) {
      this.openModal = value;
    },

    closeAddEntityWithParentDetailsModal(value) {
      this.openAddEntityWithParentDetails = value;
    },

    async fetcher(pay) {
      return await this.fetchEntities(pay);
    },
    async entityListClicked(node) {
      this.fetchEntitiesResponse = "";
      this.entityIdFromRoute = null;
      this.onEntityClickshowLoader = true;
      payloadDetails.payloadForGetEntites.languageid = this.getSelectedLanguage;
      payloadDetails.payloadForGetEntites.entityid = node.entityid;
      payloadDetails.payloadForGetEntites.TaskId = 0;
      this.fetchEntitiesResponse = await this.fetcher(
        payloadDetails.payloadForGetEntites
      );
      if (!this.fetchEntitiesResponse.isError) {
        this.addEntityButtonName = "";
        this.setSelectedEntity(this.fetchEntitiesResponse.data.entity);
        this.selectedNodeDetails = this.fetchEntitiesResponse.data.entity;
      } else {
        this.onEntityClickshowLoader = false;
        this.notificationMessage = "Something went wrong";
        this.notificationType = "error";
        this.notificationKey="success-default"
        this.showNotification = true;
      }
      this.inputsReadOnly = true;
      this.isApproveDatapoints = false;
      this.navigatedFromAssignedTOScreen = false;
      this.taskId = null;
      this.onEntityClickshowLoader = false;
    },

    async getEntityDetails(node) {
      //
      payloadDetails.payloadForGetEntites.entityid = node.entityid;
      payloadDetails.payloadForGetEntites.TaskId = 0;
      let response = await this.fetchEntities(
        payloadDetails.payloadForGetEntites
      );
      if (!response.isError) {
        this.setSelectedEntity(this.getEntities.entity);
      } else {
        this.notificationMessage = "Something went wrong";
        this.notificationType = "error";
        this.notificationKey="success-default"
        this.showNotification = true;
      }
    },

    addEntityClicked() {
      this.openModal = true;
    },

    addEntityWithParentDetails() {
      this.openAddEntityWithParentDetails = true;
    },
    async discardDraft(dataJson, entityName) {
      const confirmDiscard = await this.$refs.confirmDialogue.show({
        dialogMessage: "Are you sure you want to delete " + entityName + " ?",
        okButtonName: "Delete",
        cancelButtonName: "Cancel",
        iconDetails: "trash",
        displayConfirmButtons: true,
      });
      if (confirmDiscard) {
        this.onEntityClickshowLoader = true;
        var response = await saveDatapointAsDraft(dataJson);
        let iconDetailsForNotification;
        let iconColor;
        if (!response.isError) {
          this.notificationMessage = response.data.message;
          payloadDetails.payloadForGetEntites.entityid = null;
          payloadDetails.payloadForGetEntites.TaskId = 0;
          await this.fetchEntities(payloadDetails.payloadForGetEntites);
          this.setSelectedEntity([]);
          this.onEntityClickshowLoader = false;
          iconDetailsForNotification = "trash";
          iconColor = "red";
        } else {
          iconDetailsForNotification = "alert";
          iconColor = "red";
          if (response.data.message) {
            if (response.data.message.trim().length > 0) {
              this.notificationMessage = response.data.message;
            }
          } else {
            this.notificationMessage = "Something went wrong";
          }
        }

        this.onEntityClickshowLoader = false;
        this.inputsReadOnly = true;
        await this.$refs.confirmDialogue.show({
          dialogMessage: this.notificationMessage,
          cancelButtonName: "Close",
          iconDetails: iconDetailsForNotification,
          displayConfirmButtons: false,
          iconColor: iconColor,
          dialogSubTextMessage: this.getUTCFormatForCurrentDate(),
        });
      }
    },

    async saveAsDraft(dataJson, isSendForApproval) {
      dataJson.languageid = this.getSelectedLanguage;
      this.showDataPointArea = false;
      this.onEntityClickshowLoader = true;
      if(this.navigatedFromAssignedTOScreen == false && this.isApproveDatapoints == false){
        if(dataJson.taskid != 0){
          dataJson.taskid = 0
        }
      }
      var response = await saveDatapointAsDraft(dataJson);
      let iconDetailsForNotification;
      let iconColor;
      this.notificationMessage = "";
      if (!response.isError) {
        this.notificationMessage = response.data.message;
        if (response.data.status == -1) {
          iconDetailsForNotification = "alert";
          iconColor = "red";
          this.inputsReadOnly = false;
        } else {
          if(this.navigatedFromAssignedTOScreen){
            this.navigatedFromAssignedTOScreen=false
            this.taskid=null
          }
          if(this.isApproveDatapoints){
            this.isApproveDatapoints=false            
            this.taskid=null
          }
          iconDetailsForNotification = "check";
          iconColor = "blue";
          await this.getEntityDetails(this.getSelectedEntity);
          if (isSendForApproval) {
            this.inputsReadOnly = true;
          }
        }
      } else {
        iconDetailsForNotification = "alert";
        iconColor = "red";
        if (response.data.message) {
          if (response.data.message.trim().length > 0) {
            this.notificationMessage = response.data.message;
          }
        } else {
          this.notificationMessage = "Something went wrong";
        }
      }
      this.onEntityClickshowLoader = false;
      this.showDataPointArea = true;
      await this.$refs.confirmDialogue.show({
        dialogMessage: this.notificationMessage,
        cancelButtonName: "Close",
        iconDetails: iconDetailsForNotification,
        displayConfirmButtons: false,
        iconColor: iconColor,
        dialogSubTextMessage: this.getUTCFormatForCurrentDate(),
      });
    },

    async newEntityAdded(value) {
      this.onEntityClickshowLoader = true;
      this.openModal = false;
      this.openAddEntityWithParentDetails = false;
      var response = await insertNewEntity(value);
      this.inputsReadOnly = true;
      var addEntityResponseData = response.data.result;
      let iconDetailsForNotification;
      let iconColor;
      if (!response.isError) {
        if (response.data.message.trim().length == 0) {
          await this.getEntityDetails(addEntityResponseData);
          this.notificationMessage = "Entity successfully created";
          iconDetailsForNotification = "check";
          iconColor = "blue";
        } else {
          this.notificationMessage = response.data.message;
          iconDetailsForNotification = "alert";
          iconColor = "red";
        }
      } else {
        this.notificationMessage = "Something went wrong";
        iconDetailsForNotification = "alert";
        iconColor = "red";
      }

      this.onEntityClickshowLoader = false;
      await this.$refs.confirmDialogue.show({
        dialogMessage: this.notificationMessage,
        cancelButtonName: "Close",
        iconDetails: iconDetailsForNotification,
        displayConfirmButtons: false,
        iconColor: iconColor,
        dialogSubTextMessage: this.getUTCFormatForCurrentDate(),
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.leftBlock {
  width: 100%;
  .leftEntityBlock {
    background: #f2f2f2;
    min-height: 100vh;
    height: 100%;
    overflow: hidden;
  }
  .leftEntityBlockHeaderButtonDiv {
    display: flex;
    margin-top: 10%;
    align-items: center;
    padding: 1% 16px;
  }
  .entityContainer {
    // height: 65vh; if recent block is present
    height: 80vh;
    margin-top: 5%;
    overflow: hidden;
    overflow-y: scroll;
    padding-left: 16px;
    padding-top: 10px;
    box-shadow: inset 0 -11px 10px -12px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: inset 0 -11px 10px -12px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: inset 0 -11px 10px -12px rgba(0, 0, 0, 0.2);
  }
  /* scrollBar design starts   */
  ::-webkit-scrollbar {
    width: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    //   -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background: #f8f8f8;
    -webkit-border-radius: 10px;
    border-radius: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(194, 193, 193, 0.8);
    //   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }
  ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(228, 228, 228, 0.4);
  }
}
.rightBlock {
  width:-webkit-fill-available;
  // padding: 0 2%;
  margin-left: 16px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 16px;
}
.loaderCursor {
  cursor: not-allowed !important;
}
.breadCrumesAnchor {
  cursor: pointer;
  font-size: 16px;
  color: #006fba;
  text-decoration: underline;
}
.breadCrumesDiv {
  font-size: 16px;
  line-height: 18px;
}
.entityTitleName{
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}
</style>
