<template>
  <div>
    <mds-list-group :with-icon="true" variation="navigation" class="listGroup"
        >
      <mds-list-group-item
        :icon-left="(node.children.length > 0 || this.getSelectedEntity.entityid == node.entityid)?'triangle-fill-down':'triangle-fill-right'"
        v-on:mds-list-item-clicked="handleClick(node)"
        href="#"
        style="border-bottom:1px solid #CCC;color:#2683c3!important;"
        :style="{ 'margin-left': `${level * 20}px` }"
        :active="getActive(node)"
        :class="getActive(node) ? 'activeEntity' : ''"
        class="listItem"
      >
      <span class="listItem">{{node.entityname}} 
        <mds-tag size="small" v-if="node.isdraft">
            Draft
        </mds-tag>
         <mds-tag size="small" v-if="node.obsolete">
            Closed
        </mds-tag>
      </span>
      </mds-list-group-item>
    </mds-list-group>
    <div>
      <tree-view
        v-for="child in node.children"
        :key="child.entityid"
        :node="child"
        :level="level + 1"
        :handle-click="handleClick"
      >
      </tree-view>
    </div>
  </div>
</template>
<script>
import { MdsListGroup, MdsListGroupItem } from "@mds/list-group";
import { mapMutations, mapActions,mapGetters} from "vuex";
import { MdsTag } from '@mds/tag'
export default {
  name: "treeView",
  components: {
    MdsListGroup,
    MdsListGroupItem,
    MdsTag,
  },
  props: {
    node: Object,
    index:Number,
     level: {
      type: Number,
      default: 0,
    },
    handleClick: Function
  },
  computed:{
     ...mapGetters("entity", ["getSelectedEntity"]),
  },
  data(){
      return{
         
      }
  },
  mounted() {
    // console.log(this.index);
  },
  
  created() {
//     console.log("Updated");
//     this.$nextTick(()=>{
//          var list = document.querySelectorAll(".activeEntity");
//          var group =document.querySelectorAll(".listGroup"); 
//           console.log(list);
          
//  var top = list[0].offsetTop
// var selectTop = group[0].offsetTop;

// list.scrollTop(list.scrollTop() + (top - selectTop));
//           // if(list[0]){
//           //   console.log(list[0]);
//           //     var top = list[0].offsetTop;
//           //     console.log(top);
//           //     window.scrollTo(0, top-80);
//           // }
//        })
  },
  methods:{
       ...mapActions("entity", ["fetchEntityById"]),
    ...mapMutations("entity", ["setSelectedEntity"]), 

      getIcon(node){
          if(node.children.length > 0 || this.getSelectedEntity.entityid == node.entityid){
              return "triangle-fill-down"
          }else{
              return "triangle-fill-right"
          }
          

      },
      getActive(node){
          if(this.getSelectedEntity.entityid == node.entityid){
              return true
          }
          return false
      }
      // async getEntityByEntityId(){
      //   console.log("treee view");
      //    this.isLoading = true;
      //    this.$emit("subEntityClicked",true)
      //    this.$emit("entityClicked",true)
      // await this.fetchEntityById({ entityId: this.node.EntityId });
      // this.setSelectedEntity(this.node);
      //  this.$emit("subEntityClicked",false)
      //  this.$emit("entityClicked",false)
      // this.isLoading = false;
      // },
    //   subEntityListClicked(value){
    //     console.log("VJVJVJVJVJ",value)
    //      this.$emit("entityClicked",value)
    // },
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/css/styles.scss";

.listItem {
    // color: #2081c2 !important;
}
.listItem:focus {
  box-shadow:none;
  outline: none;
}
</style>