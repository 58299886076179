import { render, staticRenderFns } from "./DataPointsDetails.vue?vue&type=template&id=13b0acac&scoped=true&"
import script from "./DataPointsDetails.vue?vue&type=script&lang=js&"
export * from "./DataPointsDetails.vue?vue&type=script&lang=js&"
import style0 from "./DataPointsDetails.vue?vue&type=style&index=0&id=13b0acac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b0acac",
  null
  
)

export default component.exports