<template>
  <div id="data-point-details">
    <div v-if="showSubHeader">
      <sub-header-nav
        v-if="getSelectedEntity.entityname"
        :inputsReadOnly="inputsDataPointReadOnly"
        :isApproveDatapointsRoute='isApproveDatapointsRoute'
        :addInvestmentScreen="true"
        :addEntityButtonName="addSubNavEntityButtonName"
        @subNavAddEntityWithParentDetails="subNavAddEntityWithParentDetails"
        @saveDataPoint="saveDataPoint"
        @discardDraft="discardDraftDataPoint"
        @sendForApproval="sendForApproval"
        @makingDataPointsEditable="makingDataPointsEditable"
        @changeLanguage="onLanguageChange"
        @jumpToSection="jumpToSection"
         @editExternalIdentifiers="editExternalIdentifiersClick"
      ></sub-header-nav>
    </div>
    <div
      v-for="(dataPointGroup,index) in details"
      :key="index"
    >
      <mds-section
        :collapsible="dataPointGroup.type != 1"
        :expanded="dataPointGroup.type != 1"
        :border="dataPointGroup.type != 1 ? 'primary' : 'none'"
        :title="
          dataPointGroup.type != 1
            ? dataPointGroup.datapointgroupdescription
            : ''
        "
        v-if="dataPointGroup.groupAction != 'delete'"
        v-on="$listeners"
        :bold="isBold"
        :size="sectionSize"
        class="section my-section-stack-spacing"
        style="margin-bottom: 2%"
        :id="dataPointGroup.datapointgroupid"
        :ref="dataPointGroup.datapointgroupid"
      >
         <template class="section-actions" v-slot:mds-section-actions v-if="dataPointGroup.datapointslist.length > 0 && !inputsDataPointReadOnly && dataPointGroup.datapointslist.length != lengthOfDisabledDataPoints(dataPointGroup.datapointslist)">
        <mds-button class="actionButton" variation="flat" size="small" type="button" style="margin-left: 10%;" @click="onClearAllClick(dataPointGroup)">
             Clear all</mds-button>
    </template>
        <mds-row
          align-vertical="center"
          class="my-text-stack-spacing-class"
          style="margin-bottom: 1%"
          v-if="dataPointGroup.datapointslist.length > 0 && dataPointGroup.datapointslist.length != lengthOfDisabledDataPoints(dataPointGroup.datapointslist)"
        >
          <mds-col :cols="3"> </mds-col>
          <mds-col :cols="3" style="padding-left: 0"> Value </mds-col>
          <mds-col :cols="2" style="padding-left: 0;padding-right: 0"> Effective Date </mds-col>
          <mds-col :cols="2" style="padding-right: 0"> Publish Date </mds-col>
          <!-- <mds-col :cols="1"> N/A </mds-col> -->
          <mds-col :cols="1" style="padding-right: 0"> Notes </mds-col>
        </mds-row>
        <template v-for="(dataPoint,index) in dataPointGroup.datapointslist">
        <mds-row
          align-vertical="center"
          class="my-text-stack-spacing-class"
          :key="index"
          style="margin-bottom: 1%"
          v-if="!dataPoint.disabled"
        >
        <template >
          <mds-col :cols="3">
            <p :class="dataPoint.isvalidationerror == true ? 'errorClass' : ''" style="white-space: pre-wrap;">{{dataPoint.datapointname.trim()}} 
              <span style="color: red; font-weight: bold" v-if="dataPoint.isrequired">* </span> 
              <span
                style="color: red; font-weight: bold"
                v-if="dataPoint.showhistory"
                 title="Data point history"
                 @click="datapointHistory(dataPoint)" 
              > 
               <mds-icon size="small" name="clock"  style="cursor:pointer;"></mds-icon> 
              </span>
              <span
                v-if="
                  dataPoint.validitystateid != null &&
                    dataPoint.validitystateid == enums.ValidityStates.NeedsApproval &&
                    !getSelectedEntity.isdraft
                "
                :title="dataPoint.validitystatestring"
              ><mds-icon class="pendingApprovalIcon" size="small" name="alert"></mds-icon
              ></span>
              <span
                v-if="
                  dataPoint.validitystateid != null && dataPoint.validitystate == enums.ValidityStates.RejectedForEdit && !getSelectedEntity.isdraft
                "
                 :title="dataPoint.validitystatestring"
              > <mds-icon class="exclaimIcon" size="small" name="exclaim"></mds-icon></span></p>
            <country-lable v-if=" dataPoint.datapointdependencytypeenum == dependencyTypes.Country" :countryId="dataPoint.countryid" ></country-lable>
             <language-label v-if=" dataPoint.datapointdependencytypeenum == dependencyTypes.Language" :languageId="dataPoint.languageid"></language-label>
          </mds-col>
          <mds-col :cols="3" style="padding-left: 0">
            <div v-if="!inputsDataPointReadOnly">
              <div v-if="dataPoint.enumid != null">
                <div
                  v-if="dataPointGroup.type != 1 && dataPoint.arraylength != 1"
                >
                  <!-- GetSelectMultipleForEnumId -->
                  <multi-select-drop-down
                    :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                    v-model="dataPoint.values"
                    type="selectForEnum"
                    :itemArray="getValue(dataPoint)"
                    :oldItemArray="getOldValues(dataPoint)"
                    :itemId="dataPoint.enumid"
                    :isdisabled="dataPoint.isautomapped|| disableFields(dataPoint)"
                    :isValidationError="dataPoint.isvalidationerror"
                    :key="multiSelectEnumKey"
                    :isDisabledOnNa="dataPoint.isna"
                    @change="() => ((dataPoint.isvalidationerror = false))"
                    @text="(v)=>dataPoint.valuetext=v"
                    @valueChange="onValueChange(dataPoint,dataPoint.valuetext,dataPointGroup,true)"
                  ></multi-select-drop-down>
                </div>
                <div
                  v-else-if="
                    dataPoint.datapointname.toString().toUpperCase() ==
                      'FRONT LOAD COUNTRY CODE'
                  "
                >
                  <select-drop-down
                    :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                    :itemId="dataPoint.enumid"
                    :valueDetails="dataPoint.value"
                    :datapointName="dataPoint.datapointname"
                    :oldvalueDetails="dataPoint.oldvalue"
                    v-model="dataPoint.value"
                    type="selectForEnum"
                    @valueChange="onValueChange(dataPoint,$event,dataPointGroup,false)"
                    :isdisabled="dataPoint.isautomapped|| disableFields(dataPoint)"
                    :isValidationError="dataPoint.isvalidationerror"
                    :errorMessage="dataPoint.errormessage"
                    :key="selectEnumKey"
                    :isDisabledOnNa="dataPoint.isna"
                    @change="
                    () => (
                      (dataPoint.isvalidationerror = false)
                      )
                    "
                  ></select-drop-down>
                </div>
                <div v-else>
                  <!-- GetSelectForEnumId -->
                  <select-drop-down
                    :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                    :itemId="dataPoint.enumid"
                    :datapointName="dataPoint.datapointname"
                    :valueDetails="dataPoint.value"
                    :oldvalueDetails="dataPoint.oldvalue"
                    v-model="dataPoint.value"
                    type="selectForEnum"
                     @valueChange="onValueChange(dataPoint,$event,dataPointGroup,false)"
                    :isdisabled="dataPoint.isautomapped|| disableFields(dataPoint)"
                    :isValidationError="dataPoint.isvalidationerror"
                    :errorMessage="dataPoint.errormessage"
                    :key="selectEnumKeySec"
                    :isDisabledOnNa="dataPoint.isna"
                    @change="
                    () => (
                      (dataPoint.isvalidationerror = false)
                    )
                  "
                  ></select-drop-down>
                </div>
              </div>
              <div v-else-if="dataPoint.datatypeenum == dataTypes.IdInteger">
                <div
                  v-if="dataPointGroup.type != 1 && dataPoint.arraylength != 1"
                >
                  <!-- GetSelectMultipleForReferenceTypeId -->
                  <multi-select-drop-down
                    :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                    v-model="dataPoint.values"
                    type="selectForReference"
                    :itemArray="getValue(dataPoint)"
                    :oldItemArray="getOldValues(dataPoint)"
                    :itemId="dataPoint.referencedentitytypeid"
                    :isdisabled="dataPoint.isautomapped || disableFields(dataPoint)"
                    :isDisabledOnNa="dataPoint.isna"
                     @displayLoader="displayLoader"
                     :datapointName="dataPoint.datapointname"
                    :isValidationError="dataPoint.isvalidationerror"
                    :errorMessage="dataPoint.errormessage"
                    :key="multiSelectRefKey"
                    @change="
                    () => (
                      (dataPoint.isvalidationerror = false)
                    )
                  "
                  @valueChange="onValueChange(dataPoint,dataPoint.valuetext,dataPointGroup,true)"
                   @text="(v)=>dataPoint.valuetext=v"
                   
                  ></multi-select-drop-down>
                </div>
                <div v-else>
                  <!-- GetSelectForReferenceTypeId referencedentitytypeid-->
                  <select-drop-down
                    :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                    :itemId="dataPoint.referencedentitytypeid"
                    :datapointName="dataPoint.datapointname"
                    :valueDetails="dataPoint.value"
                    :oldvalueDetails="dataPoint.oldvalue"
                    v-model="dataPoint.value"
                    type="selectForReference"
                     @valueChange="onValueChange(dataPoint,$event,dataPointGroup,false)"
                     @displayLoader="displayLoader"
                     @input="updateDropDownList"
                    :isdisabled="dataPoint.isautomapped || disableFields(dataPoint)"
                    :isValidationError="dataPoint.isvalidationerror"
                    :errorMessage="dataPoint.errormessage"
                    :key="selectRefKey"
                    :isDisabledOnNa="dataPoint.isna "
                    @change="
                    () => (
                      (dataPoint.isvalidationerror = false)
                    )
                  "
                  ></select-drop-down>
                </div>
              </div>
              <!-- Market Holiday Type -->
              <div v-else-if=" dataPoint.datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE'">
                <!--TODOS: Market holiday Date Select date select should be multiple -->
                <mds-input
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                  hidden-label
                  type="date"
                  label="Default"
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  :value="dataPoint.value"
                  @change="(v) => ((dataPoint.value = onDateChange(v.target.value)),(dataPoint.isvalidationerror = false),onValueChange(dataPoint,v.target.value,dataPointGroup,false))"
                  :required="dataPoint.isrequired && !getSelectedEntity.isdraft"
                  :disabled="dataPoint.isna || dataPoint.isautomapped || disableFields(dataPoint)"
                ></mds-input>
              </div>
              <div v-else-if="dataPoint.datapointname.toString().toUpperCase()=='KEY DATE'">
                <!--TODOS: Market holiday Date Select date select should be multiple -->
                <mds-input
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                  hidden-label
                  type="date"
                  label="Default"
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  :value="dataPoint.value"
                  @change="(v) => ((dataPoint.value = onDateChange(v.target.value)),(dataPoint.isvalidationerror = false),onValueChange(dataPoint,v.target.value,dataPointGroup,false))"
                  :required="dataPoint.isrequired && !getSelectedEntity.isdraft"
                  :disabled="dataPoint.isna || dataPoint.isautomapped || disableFields(dataPoint)"
                ></mds-input>
              </div>
              <div v-else>
                 
                <mds-input
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                  hidden-label
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  class="inputRequired"
                  :required="dataPoint.isrequired && getSelectedEntity.isdraft"
                  label="Default"
                  :value="dataPoint.value"
                  :maxlength="enums.inputMaxLength"
                  @change="(v) => (
                      (dataPoint.value = v.target.value),
                      (dataPoint.isvalidationerror = false),
                      onValueChange(dataPoint,v.target.value,dataPointGroup,false)
                    )
                  "
                  :disabled="dataPoint.isna || dataPoint.isautomapped || disableFields(dataPoint)"
                  v-if="dataPoint.datatypeid == dataTypes.StringShort"
                ></mds-input>
                <!-- dataPoint.datatypeid == dataTypes.StringShort ||
                    dataPoint.datatypeid == dataTypes.Number ||
                    -->
                <mds-input
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                  hidden-label
                  type="Number"
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  class="inputRequired"
                  :required="dataPoint.isrequired && getSelectedEntity.isdraft"
                  label="Number"
                  :value="dataPoint.value"
                  :maxlength="enums.inputMaxLength"
                  onkeydown="return event.keyCode !== 69"
                  @change="
                    (v) => (
                      (dataPoint.value = v.target.value),
                      (dataPoint.isvalidationerror = false),onValueChange(dataPoint,v.target.value,dataPointGroup,false)
                    )
                  "
                  :disabled="dataPoint.isna || dataPoint.isautomapped ||disableFields(dataPoint)"
                  v-if="dataPoint.datatypeid == dataTypes.Number"
                ></mds-input>
                <mds-input
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                  hidden-label
                  type="number"
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  class="inputRequired"
                  :maxlength="enums.inputMaxLength"
                  max="2147483647"
                  :required="dataPoint.isrequired && getSelectedEntity.isdraft"
                  label="Integer"
                  :value="dataPoint.value"
                  @change="
                    (v) => (
                      (dataPoint.value = checkMax(v,dataPoint)),
                      (dataPoint.isvalidationerror = false),onValueChange(dataPoint,v.target.value,dataPointGroup,false)
                    )
                  "
                  onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
                  :disabled="dataPoint.isna || dataPoint.isautomapped || disableFields(dataPoint)"
                  v-if="dataPoint.datatypeid == dataTypes.Integer"
                ></mds-input>
                <!-- 
                   -->
                <mds-textarea
                  hidden-label
                  label="Add"
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  :required="dataPoint.isrequired && getSelectedEntity.isdraft"
                  :disabled="dataPoint.isna || dataPoint.isautomapped || disableFields(dataPoint)"
                  :value="dataPoint.value"
                  @change="
                    (v) => (
                      (dataPoint.value = v.target.value),
                      (dataPoint.isvalidationerror = false),onValueChange(dataPoint,v.target.value,dataPointGroup,false)
                    )
                  "
                  v-if="dataPoint.datatypeid == dataTypes.StringLong"
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                ></mds-textarea>
                <mds-input
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                  hidden-label
                  type="date"
                  label="Date"
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  :value="dataPoint.value"
                  @change="
                    (v) => (
                      (dataPoint.value = onDateChange(v.target.value)),
                      (dataPoint.isvalidationerror = false),
                      onValueChange(dataPoint,v.target.value,dataPointGroup,false)
                    )
                  "
                  v-if="dataPoint.datatypeid == dataTypes.Date"
                  :required="dataPoint.isrequired && getSelectedEntity.isdraft"
                  :disabled="dataPoint.isna || dataPoint.isautomapped || disableFields(dataPoint)"
                ></mds-input>
                <mds-input
                  :id="dataPoint.datapointname.replace(/ /g,'_')+'_Value_'+dataPoint.arrayindex"
                  hidden-label
                  type="time"
                  label="Time"
                  step="1"
                  :error="dataPoint.isvalidationerror"
                  :error-text="dataPoint.errormessage"
                  :value="dataPoint.value"
                  @change="
                    (v) => (
                      (dataPoint.value = v.target.value),
                      (dataPoint.isvalidationerror = false),onValueChange(dataPoint,v.target.value,dataPointGroup,false)
                    )
                  "
                  v-if="dataPoint.datatypeid == dataTypes.Time"
                  :required="dataPoint.isrequired && getSelectedEntity.isdraft"
                  :disabled="dataPoint.isna || dataPoint.isautomapped || disableFields(dataPoint)"
                ></mds-input>
              </div>
            </div>
            <p
              v-if=" inputsDataPointReadOnly && dataPoint.datatypeid == dataTypes.Date " style="white-space: pre-wrap;"
            >{{ dateFormat(dataPoint.value) }}</p>
            <p
              v-if="inputsDataPointReadOnly && dataPoint.datatypeid != dataTypes.Date" style="white-space: pre-wrap;"
            >{{ dataPoint.valuetext}}</p>
           

          </mds-col>
          <mds-col :cols="2" style="padding-left: 0;padding-right:0;">
            <!-- 
              @change="(v) => (dataPoint.effectivedate = v.target.value)" -->
            <mds-input
              :id="dataPoint.datapointname.replace(/ /g,'_')+'_Effectivedate_'+dataPoint.arrayindex"
              class="inputField"
              hidden-label
              type="date"
              label="Default"
              :value="dataPoint.effectivedate"
              @change="(v) => (dataPoint.effectivedate = onDateChange(v.target.value))"
              v-if="!inputsDataPointReadOnly"
              :disabled="dataPoint.isna || disableFields(dataPoint)"
            ></mds-input>
            <p v-if="inputsDataPointReadOnly">
              {{ dataPoint.effectivedatestring}}
            </p>
          </mds-col>
          <mds-col :cols="2" style="padding-right: 0">
            <!-- <input type="date" class="inputField" /> -->
            <mds-input
              :id="dataPoint.datapointname.replace(/ /g,'_')+'_Disseminationdate_'+dataPoint.arrayindex"
              hidden-label
              type="date"
              label="Default"
              :value="dataPoint.disseminationdate"
              @change="(v) => (dataPoint.disseminationdate = onDateChange(v.target.value))"
              v-if="!inputsDataPointReadOnly"
              :disabled="dataPoint.isna || disableFields(dataPoint)"
            ></mds-input>
            <p v-if="inputsDataPointReadOnly">
              {{ dataPoint.disseminationdatestring }}
            </p>
          </mds-col>
          <mds-col
            :cols="1"
            style="text-align: center; display: flex; padding-right: 0"
          >
            <div style="height: 20px; margin-right: 10px">
              <a
                href="#"
                @click.prevent="dataPoint.shownote = !dataPoint.shownote"
                :class="{ disabled: inputsDataPointReadOnly }"
                :disabled="inputsDataPointReadOnly"
                style="position: relative;"
              >
              
                <span v-if="dataPoint.inputternote || dataPoint.note" class="noteIdicator"></span>
                <mds-icon
                  :id="'dataPoint'+dataPoint.arrayindex+dataPoint.datapointid"
                  name="document"
                >
                </mds-icon>
              </a>
            </div>
            <div
              style="height: 20px"
              v-if="
                (!dataPoint.isrequired || dataPoint.warningonly) &&
                  dataPoint.shownacheckbox
              "
            >
              <mds-switch
              :id="dataPoint.datapointname.replace(/ /g,'_')+'_Switch_'+dataPoint.arrayindex"
                :disabled="inputsDataPointReadOnly || disableFields(dataPoint)"
                v-if="dataPoint.shownacheckbox"
                hidden-label
                v-model="dataPoint.isna"
              ></mds-switch>
            </div>
          </mds-col>
          <!-- <mds-col :cols="1" style="text-align: center">
          
        </mds-col> -->
          <mds-col :cols="12">
            <mds-row
              v-if="
                dataPoint.datapointname.toString().toUpperCase() ==
                  'MARKET HOLIDAY DATE'
              "
              style="margin-top: 1%"
            >
              <div>&nbsp;</div>
              <mds-col :cols="3">
                <span>Market Holiday Selected Dates</span>
              </mds-col>
              <mds-col :cols="9" style="padding-left: 0">
                <div
                  class="Content"
                  style="
                    min-height: 36px;
                    background-color: rgb(229 229 229);
                    border: 1px solid #e3e3e3;
                    border-radius: 3px;
                    width: 100%%;
                  "
                >
                <span v-if="dataPoint.value != null" style="margin-left:2%">{{dateFormat(dataPoint.value)}}</span>
                </div>
              </mds-col>
            </mds-row>
          </mds-col>

          <mds-popover
            v-if="dataPoint.shownote"
            title="Note"
            @mds-popover-dismissed="dataPoint.shownote = false"
            :triggered-by="'dataPoint'+dataPoint.arrayindex+dataPoint.datapointid"
            :visible="dataPoint.shownote"
            auto-position
            position="bottom-left"
            class="notePopOver"
          >
            <template
              v-if="!inputsDataPointReadOnly"
              v-slot:mds-popover-actions
            >
              <mds-button-container>
                <mds-button
                  variation="secondary"
                  size="small"
                  @click="
                    dataPoint.shownote = false,
                    dataPoint.note = dataPoint.oldnote,
                    dataPoint.inputternote = dataPoint.oldnote
                  "
                  >Discard Changes</mds-button
                >
                <mds-button
                  variation="primary"
                  size="small"
                  @click="dataPoint.shownote = false"
                  >Save</mds-button
                >
              </mds-button-container>
            </template>
            <mds-textarea
              hidden-label
              :id="dataPoint.datapointname.replace(/ /g,'_')+'_Note_'+dataPoint.arrayindex"
              label="Add Note"
              placeholder="Add Note"
              :value="dataPoint.inputternote"
              @change="(v) => (dataPoint.note = v.target.value,dataPoint.inputternote = v.target.value)"
              :readonly="inputsDataPointReadOnly"
            ></mds-textarea>
          </mds-popover>
          </template>
        </mds-row>
        </template>
     <mds-row
          v-if="dataPointGroup.type == 1 && !inputsDataPointReadOnly"
          style="margin: 0; justify-content: flex-end"
        >
          <mds-button-container>
            <!--  -->
            <mds-button
              v-if="dataPointGroup.datapointgroupid == 56"
              variation="secondary"
              :disabled="disableAddRemoveToBenchmarkHistory(dataPointGroup)"
              size="small"
              @click="onAddTOBenchmarkHistory(dataPointGroup,index)"
              >Add to benchmark history</mds-button
            >
            <mds-button
              variation="secondary"
              size="small"
              :disabled="disableAddRemoveToBenchmarkHistory(dataPointGroup)"
              @click="onAddAnotherClick(dataPointGroup,index)"
              >Add Another</mds-button
            >
            <mds-button v-if="dataPointGroup.datapointslist[0].arrayindex != 0" variation="secondary" size="small" :disabled="disableAddRemoveToBenchmarkHistory(dataPointGroup)" @click="onRemoveClick(dataPointGroup,index)">Remove</mds-button>
          </mds-button-container>
        </mds-row>
        <div v-if="dataPointGroup.datapointgroups.length > 0">
          <data-points-details
            v-on="$listeners"
            :selectedEntityDataPointGroupDetails="dataPointGroup"
            :showSubHeader="false"
            :sectionSize="7"
            :isBold="false"
            :inputsDataPointReadOnly="inputsDataPointReadOnly"
            :navigatedFromAssignedTOScreen="isnavigatedFromAssignedTOScreen"
          >
          </data-points-details>
        </div>
        <!-- <mds-row
          style="margin: 0; justify-content: flex-end"
          
              v-if="!getSelectedEntity.isdraft  && getSelectedEntity.isapproved  && !inputsDataPointReadOnly && isBold && !isApproveDatapointsRoute"
        >
        
        <mds-button
              variation="primary"
              size="small"
              @click="approvedEntitySendForApproval(dataPointGroup)"
              >Send for Approval</mds-button
            >
        </mds-row> -->
        
      </mds-section>
    </div>
    <popover-component
      v-if="false"
      popOverSelection="dataManagement"
      title="Note"
      position="bottom-left"
      :triggerBy="triggerByDatapointId"
    ></popover-component>
    <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="5000"
      @close="showNotification = false"
    ></notification-component>
     <div class="loaderCursor" v-if="showLoader">
      <loader-component loaderSize="large"></loader-component>
    </div>
    <datapoint-history-component @dataPointHistoryModalDismiss="dataPointHistoryModalDismiss" v-if="showDataPointHistory" :historyData="dataPointHistoryData" @clickPrintHistory="clickPrintHistory" :selectedDataPointDetails='selectedDataPoint'></datapoint-history-component>
    <mds-modal class="targetDatapointModal" v-model="showAutoMappedPopup" title="Auto Mapped datapoints" :width="'600px'" action-required>
        <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
                <mds-button @click="onCancelShowAutoMapped" variation="secondary">Cancel</mds-button>
                <mds-button @click="showAutoMappedPopup = !showAutoMappedPopup" variation="primary">Ok</mds-button>
            </mds-button-container>
      </template>
      <p style="margin-bottom:16px;">This is auto mapped datapoint on changing value it will auto update the target datapoint values</p>
      <mds-table>
      <mds-thead>
          <mds-th v-for="(header, index) in targetDatapointHeader" :key="index"
          > {{header.text}} </mds-th>
      </mds-thead>
      <mds-tbody>
          <mds-tr
              v-for="(row, index) in targetDatapointDetails"
              :key="index"
          >
              <mds-td
                  v-for="(header, index) in targetDatapointHeader"
                  :key="index"
              > {{ row[header.fieldName] }} </mds-td>
          </mds-tr>
      </mds-tbody>
</mds-table>
</mds-modal>
<auto-mapped-popup ref="confirmAutoMapDialogue">

</auto-mapped-popup>
  </div>
</template>
<script>
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table-4.0.2';
import MdsSection from "@mds/section";
import { MdsRow, MdsCol } from "@mds/layout-grid";
import MdsSwitch from "@mds/switch";
import MdsInput from "@mds/input";
import MdsIcon from "@mds/icon";
import SubHeaderNav from "./subHeaderNav.vue";
import { mapGetters } from "vuex";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import PopoverComponent from "../ui_component/popoverComponent.vue";
import { MdsButton, MdsButtonContainer } from "@mds/button";
import MdsPopover from "@mds/popover";
import MdsTextarea from "@mds/textarea";
import SelectDropDown from "../ui_component/selectDropDown.vue";
import MultiSelectDropDown from "../ui_component/multiSelectDropDown.vue";
import DatapointHistoryComponent from './DatapointHistoryComponent' ;
import { getDataPointHistory} from "../../services/add_investment_service";
import { getCountry} from "../../services/get_default_values.service"; 
import Enums from '../../config/Enums.json'
import { dateFormatterMixin } from '../../mixins/date';
import LanguageLabel from './LanguageLabel.vue';
import LoaderComponent from "../ui_component/loaderComponent"
import {getAutoMappingDetails} from "../../services/add_investment_service.js";
import MdsModal from '@mds/modal';
import AutoMappedPopup from './autoMappedPopup.vue';
export default {
  name: "dataPointsDetails",
  components: {
    MdsButton,
    MdsButtonContainer,
    MdsPopover,
    MdsTextarea,
    MdsSection,
    MdsRow,
    MdsCol,
    MdsSwitch,
    MdsInput,
    MdsIcon,
    SubHeaderNav,
    PopoverComponent,
    SelectDropDown,
    MultiSelectDropDown,
    NotificationComponent,
    DatapointHistoryComponent,
    LanguageLabel,
    LoaderComponent,
    MdsModal,
    MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
    AutoMappedPopup
  },
  mixins: [ dateFormatterMixin ],
  computed: {
    ...mapGetters("entity", ["getSelectedEntity", "getEntities"]),
    ...mapGetters("dropDowns", ["getEnumDropDowns","getReferenceDropDowns"]),
    getCountry(){
     return this.getCountryDetails()
    },
    
  },
  props: {
    taskId:{
      type:Number
    },
    navigatedFromAssignedTOScreen:{
      type:Boolean
    },
    selectedEntityDataPointGroupDetails: {
      required: true,
    },
    addSubNavEntityButtonName: {
      type: String,
    },
    isApproveDatapointsRoute:{
       type: Boolean,
    },
    inputsDataPointReadOnly: {
      type: Boolean,
    },
    showSubHeader: {
      type: Boolean,
      required: true,
    },
    sectionSize: {
      type: Number,
      required: true,
    },
    isBold: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isnavigatedFromAssignedTOScreen:false,
      sourceDataPoint:{},
      showAutoMappedPopup:false,
      showLoader:false,
      enums:Enums,
      selectedDataPoint:'',
      languageValue:'',
      showDataPointHistory:false,
      notificationKey:'',
      notificationType:'',
      showNotification:false,
      notificationMessage:null,
      multiSelectRefKey:0,
      multiSelectEnumKey:0,
      selectRefKey:0,
      selectEnumKeySec:0,
      selectEnumKey:0,
      // addAnotherExistingGroupDetails:{
      //   arrayindex:null,
      //   datapointgroupdescription:'',
      //   datapointgroupid:undefined,
      //   datapointgroups:[],
      //   datapointgrouptypeid:undefined,
      //   datapointslist:[],
      //   hidden:false,
      //   parentdatapointgroupid:undefined,
      //   sortorder:null,
      //   type:undefined
      // },
      noteValue: "",
      triggerByDatapointId: "",
      details: [],
      showFieldError: false,
      //   inputsDataPointReadOnly:false,
      saveDraftDatapointPayload: {
        entityid: 0,
        languageid: "en",
        taskid:0,
        countryid: null,
        draft: true,
        save :false,
        discardDraft: false,
        data: [],
      },
      popOverToggle: false,
      note: null,
      dataTypes: {
        Integer: 1,
        Number: 2,
        Date: 3,
        Time: 4,
        StringShort: 5,
        StringLong: 6,
        IdInteger: 7,
      },
      dependencyTypes:{
        None:0,
        Language:1,
        Country:2
    },
    targetDatapointHeader:[
      {
        text: 'Target Datapoint Name',
        fieldName: 'datapointname',
      },
      {
         text: 'Target Value',
        fieldName: 'targetValueText',
        
      }
    ],
    targetDatapointDetails:[]
    };
  },
  mounted() {
    this.isnavigatedFromAssignedTOScreen=this.navigatedFromAssignedTOScreen
    this.details = this.selectedEntityDataPointGroupDetails.datapointgroups;
    if(this.taskId != null){
       this.saveDraftDatapointPayload.taskid = this.taskId
       }
  },

  updated() {
    // this.$nextTick(()=>{
    //      var list = document.querySelectorAll(".errorClass");
    //       if(list[0]){
    //           var top = list[0].offsetTop;
    //           window.scrollTo(0, top-80);
    //       }
    //    })
  },
  methods: {
    checkMax(event,dataPoint){
      if(event.target.value > 2147483647){
         this.notificationType = "warning"
          this.notificationMessage="Value must be less than or equal to 2147483647"
          this.notificationKey = "warning-default"
          this.showNotification = true
        return dataPoint.oldvalue
      }else{
        return event.target.value
      }
    },
    onDateChange(date){
      if(date.length == 0){
        return null
      }
      return date
    },
    editExternalIdentifiersClick(){
      this.$emit("editExternalIdentifiersClick")
    },
    // this is to jump to the particular section selected from the subheader nav
    jumpToSection(sectionId) {
      const domNode = this.$refs[sectionId];
      window.scroll(0, domNode[0].$el.offsetTop - 52);
    },

    lengthOfDisabledDataPoints(datapointList){
      let disabledDatapoints = datapointList.filter(datapoint => datapoint.disabled == true)
      return disabledDatapoints.length
    },
    disableAddRemoveToBenchmarkHistory(dataPointGroup){
      if(!this.getSelectedEntity.isdraft){
        if(this.navigatedFromAssignedTOScreen){
          return false
      }else{
          for(let i=0;i<dataPointGroup.datapointslist.length;i++){
          if(dataPointGroup.datapointslist[i].disabledwfdatapoint){
            return true
          }else if(dataPointGroup.datapointslist[i].validitystateid == this.enums.ValidityStates.NeedsApproval || 
              dataPointGroup.datapointslist[i].validitystateid==this.enums.ValidityStates.RejectedForEdit){
            return true
          }else{
            if(i == dataPointGroup.datapointslist.length-1){
              return false
            }
          }

        }
      }
      }else{
        return false
      }
      
    },
    disableFields(datapoint){
      if(this.navigatedFromAssignedTOScreen){
        if(datapoint.disabledwfdatapoint){
          return true
        }else{
          return false
        }
      }else{
        if(datapoint.disabledwfdatapoint){
          return true
        }else if(datapoint.validitystateid == null){
          return false
        }else if(this.getSelectedEntity.isdraft && datapoint.validitystateid==this.enums.ValidityStates.NeedsApproval){
          return false
        }else if((datapoint.validitystateid == this.enums.ValidityStates.RejectedForEdit || datapoint.validitystateid==this.enums.ValidityStates.NeedsApproval) && !this.getSelectedEntity.isdraft){
          return true
        }else{
          return false
        }
      }
     
      // else if(){

      // }
      // 
    },
     async onValueChange(datapoints,event,dataPointGroup,isMultiSelect){
          let value=''
      if(isMultiSelect){
        if(event != null){
          value =event.replace(/;/g, '#$%')
        }else{
          value = event
        }
      }else{
        value = event
      }
      // automappeddatapoints
      var autoMappedDatapoint = this.getEntities.automappeddatapoints.filter(datapoint =>datapoint == datapoints.datapointid)
      if(autoMappedDatapoint.length > 0){
        this.showLoader=true
        this.sourceDataPoint=datapoints
        var response = await getAutoMappingDetails(datapoints.datapointid,value)
          if(!response.isError){
            if(response.data.length > 0){
              this.targetDatapointDetails=response.data
              var groupedObjects = this.groupBy(this.targetDatapointDetails,"targetdatapointid")
             let mergedUniqueList = Array.from(this.targetDatapointDetails.reduce((entryMap, e) => entryMap.set(e.targetdatapointid, {...entryMap.get(e.targetdatapointid)||{}, ...e}),new Map()).values());
              for(let i=0;i<mergedUniqueList.length;i++){
              let datapointObject = await this.getDataPointDetailsByDatapointId(mergedUniqueList[i].targetdatapointid,this.getEntities.datapointgroups,undefined)
               this.showLoader=false
              if(datapointObject != undefined){
              if((datapointObject.datapointList.enumid!=null || datapointObject.datapointList.datatypeenum == this.dataTypes.IdInteger)  && datapointObject.datapointList.arraylength!=1 && datapointObject.datapointGroup.type !=1){
                    let dropDownObject=[]
                    if(datapointObject.datapointList.enumid!=null){
                      dropDownObject=this.getEnumDropDowns.filter(e=>e.enumid == datapointObject.datapointList.enumid)
                    }else if(datapointObject.datapointList.datatypeenum == this.dataTypes.IdInteger){
                      dropDownObject = this.getReferenceDropDowns.filter(e=>e.refrenceid == datapointObject.datapointList.refrenceid)
                    }
                      if(mergedUniqueList[i].targetdatapointid == groupedObjects[mergedUniqueList[i].targetdatapointid][0].targetdatapointid){
                        let groupObject=groupedObjects[mergedUniqueList[i].targetdatapointid]
                        if(groupObject.length > 1 || (datapointObject.datapointList.enumid!=null && datapointObject.datapointList.arraylength!=1 && datapointObject.datapointGroup.type !=1)){
                          let targetValueText =''
                          let targetValues=[]
                          for(let l=0;l<groupObject.length;l++){
                            if(targetValueText.length == 0){
                              targetValueText=groupObject[l].targetvalue
                            }else{
                              targetValueText=targetValueText+","+groupObject[l].targetvalue
                            }
                            if(groupObject[l].targetvalue != null){
                            let valueObject = dropDownObject[0].values.filter(v=>v.text==groupObject[l].targetvalue)
                            targetValues.push(valueObject[0].value)
                            }
                          }
                          mergedUniqueList[i].targetvalue=targetValues
                          mergedUniqueList[i]["targetValueText"]=targetValueText
                        }else{
                          mergedUniqueList[i]["targetValueText"]=mergedUniqueList[i].targetvalue
                        }
                      }
                    mergedUniqueList[i].datapointname= datapointObject.datapointList.datapointname
              }else{
                mergedUniqueList[i].datapointname= datapointObject.datapointList.datapointname
                mergedUniqueList[i]["targetValueText"]=mergedUniqueList[i].targetvalue
              }
              }
              }
              for(let m=0;m<mergedUniqueList.length;m++){
                if(mergedUniqueList[m].datapointname != null){
                  const confirmDiscard = await this.$refs.confirmAutoMapDialogue.show({ 
                      targetDatapointDetails: mergedUniqueList,
                      targetDatapointHeader: this.targetDatapointHeader,
                    });
                    if(confirmDiscard){
                        for(let k=0;k<mergedUniqueList.length;k++){
                          await this.changeTargetDatapointValue(this.getEntities.datapointgroups,mergedUniqueList[k],datapoints.arrayindex,false)
                          this.selectRefKey+=1
                          this.selectEnumKeySec+=1
                          this.selectEnumKey+=1
                        }
                        if(dataPointGroup.parentdatapointgroupid != null){
                           this.$emit("onAutoMappedDatapointChange")

                        }
                    }else{
                      datapoints.value= datapoints.oldvalue
                      for(let ki=0;ki<mergedUniqueList.length;ki++){
                         await this.changeTargetDatapointValue(this.getEntities.datapointgroups,mergedUniqueList[ki],datapoints.arrayindex,true)
                        }
                      if(isMultiSelect){
                         datapoints.values=datapoints.oldValues
                         
                         this.multiSelectEnumKey+=1
                         this.multiSelectRefKey+=1
                      }
                      this.selectRefKey+=1
                      this.selectEnumKeySec+=1
                      this.selectEnumKey+=1
                    }
                    break;
                }else{
                  if(m == mergedUniqueList.length-1){
                     this.showLoader=false
                  }
                }
              }
              
            }
           
          }
          this.showLoader=false
      }
      
    },
    groupBy(array, key) {
      const result = {};
      array.forEach((item) => {
        if (!result[item[key]]) {
          result[item[key]] = [];
        }
        result[item[key]].push(item);
      });
      return result;
    },
    // this.getEntities.datapointgroups
   async getDataPointDetailsByDatapointId(datapointId,datapointGroup,value){
     var obj=value
      for(let i=0;i<datapointGroup.length;i++){
        for(let j=0;j<datapointGroup[i].datapointslist.length;j++){
           if(datapointGroup[i].datapointslist[j].datapointid ==datapointId){
            obj={
              datapointGroup:datapointGroup[i],
              datapointList:datapointGroup[i].datapointslist[j]
            }
             return obj
           }
        }
        if(datapointGroup[i].datapointgroups.length > 0){
           obj = await this.getDataPointDetailsByDatapointId(datapointId,datapointGroup[i].datapointgroups,obj)    
        }
      }
      return obj
    },
    onCancelShowAutoMapped(){
      this.sourceDataPoint.value = this.sourceDataPoint.oldvalue
       this.showAutoMappedPopup=false;
    },
    changeTargetDatapointValue(datapointGroups,targetDataPointDetails,arrayindex,isCancelClick){
      for(let i=0;i<datapointGroups.length;i++){
        for(let j=0;j<datapointGroups[i].datapointslist.length;j++){
          if(datapointGroups[i].datapointslist[j].datapointid == targetDataPointDetails.targetdatapointid && datapointGroups[i].datapointslist[j].arrayindex == arrayindex){
            if(!isCancelClick){
                  if(targetDataPointDetails.targetvalue == "Not Applicable"){
                  
                  datapointGroups[i].datapointslist[j].value= null
                  datapointGroups[i].datapointslist[j].isna= true
                }else{
                  if((datapointGroups[i].datapointslist[j].enumid!=null || datapointGroups[i].datapointslist[j].datatypeenum == this.dataTypes.IdInteger)  && datapointGroups[i].datapointslist[j].arraylength!=1 && datapointGroups[i].type !=1){
                    datapointGroups[i].datapointslist[j].values=targetDataPointDetails.targetvalue
                    datapointGroups[i].datapointslist[j].value= targetDataPointDetails.targetvalue
                  }else{
                    datapointGroups[i].datapointslist[j].value= targetDataPointDetails.targetvalue
                  }
                if(datapointGroups[i].datapointslist[j].isna){
                  datapointGroups[i].datapointslist[j].isna= false
                }
                }

            }else{
              datapointGroups[i].datapointslist[j].value=datapointGroups[i].datapointslist[j].oldvalue
              datapointGroups[i].datapointslist[j].isna= datapointGroups[i].datapointslist[j].oldna 
            }
            
             this.selectRefKey+=1
              this.selectEnumKeySec+=1
              this.selectEnumKey+=1
            return 
            }
        }
              if(datapointGroups[i].datapointgroups.length > 0){
              this.changeTargetDatapointValue(datapointGroups[i].datapointgroups,targetDataPointDetails,arrayindex,isCancelClick)
                
              }
      }
    },
    displayLoader(value){
      this.showLoader=value
    },
    getValue(dataPoint){
      if(dataPoint.values != null){
        if(dataPoint.values[0] == 0){
          return null
        }else{
           return dataPoint.values
           }
      }else{
        return dataPoint.value
      }
    },
    getOldValues(dataPoint){
      if(dataPoint.oldValues != null){
        if(dataPoint.oldValues[0] == 0){
          return null
        }else{
           return dataPoint.oldValues
           }
      }else{
        return dataPoint.oldvalue
      }
    },
    onClearAllClick(groupDetails){
       for(let i=0;i<groupDetails.datapointslist.length;i++){
       groupDetails.datapointslist[i].value=groupDetails.datapointslist[i].oldvalue
       if(groupDetails.datapointslist[i].oldeffectivedate != null){
          groupDetails.datapointslist[i].effectivedate = dateFormatterMixin.methods.reverseTheDate(groupDetails.datapointslist[i].oldeffectivedate);
       }else{
         groupDetails.datapointslist[i].effectivedate = groupDetails.datapointslist[i].oldeffectivedate
       }
       if(groupDetails.datapointslist[i].olddisseminationdate != null){
          groupDetails.datapointslist[i].disseminationdate = dateFormatterMixin.methods.reverseTheDate(groupDetails.datapointslist[i].olddisseminationdate);
       }else{
         groupDetails.datapointslist[i].disseminationdate =groupDetails.datapointslist[i].olddisseminationdate
       }
       groupDetails.datapointslist[i].note =groupDetails.datapointslist[i].oldnote
       groupDetails.datapointslist[i].inputternote =groupDetails.datapointslist[i].oldnote
       groupDetails.datapointslist[i].isna = groupDetails.datapointslist[i].oldna

       
       if(groupDetails.datapointslist[i].datatypeid == 3 && groupDetails.datapointslist[i].value != null){
            groupDetails.datapointslist[i].value = groupDetails.datapointslist[i].value.toString();
          }
      if( groupDetails.datapointslist[i].datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE'){
          if(groupDetails.datapointslist[i].value){
            groupDetails.datapointslist[i].value = dateFormatterMixin.methods.convertReportingParameterDate(groupDetails.datapointslist[i].value.toString())
          }
        }
        if (
            groupDetails.datapointslist[i].values != undefined &&
            groupDetails.datapointslist[i].values.constructor === Array
          ){
            groupDetails.datapointslist[i].values =  groupDetails.datapointslist[i].oldValues
          }
      }
      
      this.selectRefKey++;
      this.selectEnumKey++;
      this.selectEnumKeySec++;

      // if(groupDetails.datapointgroups.length > 0){
      //   this.onClearAllClick(groupDetails.datapointgroups)
      // }
    },
    clickPrintHistory(element,elementStyle){
      //  this.showDataPointHistory = false
       const WinPrint = window.open('', '', 'left=0,top=0,width=1200,height=1000,toolbar=0,scrollbars=0,status=0');

          WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${elementStyle}
              <style>
              .mds-data-table__header-cell___VueMDS3Demo{
                width:auto !important;
              }
              .mds-data-table__cell___VueMDS3Demo{
                overflow-wrap: anywhere;
              }
              </style>
            </head>
            <body>
              ${element}
            </body>
          </html>`);

          WinPrint.document.close();
          WinPrint.focus();
          WinPrint.print();
          WinPrint.close();
        // this.$router.push({
        //   name:'Datapoint History',
        //   params:{
        //     historyData:this.dataPointHistoryData,
        //     selectedDataPointDetails:this.selectedDataPoint,
        //     showModal:false
        //   }
        // });
    },
    async getCountryDetails(value){
      let country = getCountry(value)
      return country.data 
    },
    //  getLanguageDetail :async function(value){
    //   var language = await getLanguage(value)
    //   if(language.status == 200){
    //     this.languageValue= language.data
    //   }else{
    //     this.languageValue = value
    //   }
      
    // },
    
    async onAddAnotherClick(groupDetails,index) {
      await this.addAnotherTOSelectedEntity(
        this.getEntities.datapointgroups,
        groupDetails,index
      );
    },
   async onRemoveClick(groupDetails,index){
       await this.removeGroup(
        this.getEntities.datapointgroups,
        groupDetails,index
      );
    },
    removeGroup(orginalData,groupDetails,index){
       for (let i = 0; i < orginalData.length; i++) {
        if ( orginalData[i].datapointgroupid == groupDetails.parentdatapointgroupid){
         let groupsWithSameId = orginalData[i].datapointgroups.filter((group) => {
          return (group.datapointgroupid == groupDetails.datapointgroupid && group.groupAction != "delete")});
        if(groupsWithSameId.length > 1){
          
          if(orginalData[i].datapointgroups[index].groupAction == "add"){
              orginalData[i].datapointgroups.splice(index,1)
              let lengthOfGroups = orginalData[i].datapointgroups.filter((group)=>group.datapointgroupid == groupDetails.datapointgroupid)
              for(let j=0;j<lengthOfGroups.length;j++){
                for(let k=0;k<lengthOfGroups[j].datapointslist.length;k++){
                  lengthOfGroups[j].datapointslist[k].arrayindex=j
                }
              }
          }else{
              orginalData[i].datapointgroups[index].groupAction="delete"
               for(let m=0;m<orginalData[i].datapointgroups[index].datapointslist.length;m++){
                      orginalData[i].datapointgroups[index].datapointslist[m].arrayindex=-1
                      orginalData[i].datapointgroups[index].datapointslist[m].disseminationdate= null
                      orginalData[i].datapointgroups[index].datapointslist[m].disseminationdatestring= null
                      orginalData[i].datapointgroups[index].datapointslist[m].effectivedate= null
                      orginalData[i].datapointgroups[index].datapointslist[m].effectivedatestring= null
                      orginalData[i].datapointgroups[index].datapointslist[m].isna=false
                      orginalData[i].datapointgroups[index].datapointslist[m].note=null
                      orginalData[i].datapointgroups[index].datapointslist[m].value= null
              }
          }
          this.multiSelectRefKey +=1
          this.multiSelectEnumKey+=1
          this.selectRefKey+=1
          this.selectEnumKeySec+=1
          this.selectEnumKey+=1
        }else{
          this.notificationType = "warning"
          this.notificationMessage="Can't remove all of these."
          this.notificationKey = "warning-default"
          this.showNotification = true
        }
          break;
        }else{
          if (orginalData[i].datapointgroups.length > 0) {
          this.removeGroup(
            orginalData[i].datapointgroups,
            groupDetails,index
          );
        }
        }
       }
    },
    async approvedEntitySendForApproval(group){
      let approvedGroup = [];
      approvedGroup.push(group);
       let approvedJsonConverted = await this.convertApprovedJson(approvedGroup);
       this.$emit("saveAsDraft", approvedJsonConverted);
    },
   
    onLanguageChange(value) {
      this.$emit("onLanguageChanged", value);
    },
    dateFormat(value) {
      if (value) {
        return dateFormatterMixin.methods.convertAdditionalSearchDate(value);
      }
      return value;
    },

    resetSaveDraftPayload(){
      this.saveDraftDatapointPayload.entityid =0
       this.saveDraftDatapointPayload.languageid= "en"
        this.saveDraftDatapointPayload.taskid=0
        this.saveDraftDatapointPayload.countryid= null
        this.saveDraftDatapointPayload.draft= true
        this.saveDraftDatapointPayload.save =false
        this.saveDraftDatapointPayload.discardDraft= false
        this.saveDraftDatapointPayload.data= []
    },
    async saveDataPoint() {
      this.resetSaveDraftPayload()
      var isDraft = true;
      let EditedDatapoints ={}
      let datapointsValuesChanged=[]
      var convertedJson = await this.convertJson(isDraft);
        EditedDatapoints= {
            countryid: convertedJson.countryid,
            data: [],
            discardDraft: convertedJson.discardDraft,
            draft: convertedJson.draft,
            entityid: convertedJson.entityid,
            languageid: convertedJson.languageid,
            save: convertedJson.save,
            taskid: convertedJson.taskid
          }
      for(let i=0;i<convertedJson.data.length;i++){
        // if(convertedJson.data[i].oldvalue != null && convertedJson.data[i].value!=null){

        // }
        if(convertedJson.data[i].value != convertedJson.data[i].oldvalue || convertedJson.data[i].disseminationdate != convertedJson.data[i].olddisseminationdate || convertedJson.data[i].effectivedate != convertedJson.data[i].oldeffectivedate || 
        convertedJson.data[i].na !== convertedJson.data[i].oldna || convertedJson.data[i].note != convertedJson.data[i].oldnote){
          // EditedDatapoints.data.push(convertedJson.data[i])
          datapointsValuesChanged.push(convertedJson.data[i])
        }
      }
      datapointsValuesChanged = this.getUnique(datapointsValuesChanged,["datapointid"])
      for(let i=0;i<datapointsValuesChanged.length;i++){
        for(let j=0;j<convertedJson.data.length;j++){
          if(datapointsValuesChanged[i].datapointid == convertedJson.data[j].datapointid){
            convertedJson.data[j]["isDatapointChanged"]=1
            EditedDatapoints.data.push(convertedJson.data[j])
            // if(convertedJson.data[j].datapointid == Enums.SEDOLShareClass){
            //    EditedDatapoints.data.push(convertedJson.data.filter(datapoint => datapoint.datapointid == Enums.SEDOLStartDate)[0])
            // }
          }
        }
      }
      if(EditedDatapoints.data.length > 0){
       this.$emit("saveAsDraft", EditedDatapoints,false);
      }else{
        this.notificationType = "informational"
        this.notificationMessage="No changes were made."
        this.notificationKey = "informational-default"
        this.showNotification = true
      } 
    },
getUnique(arr, keyProps) {
      const kvArray = arr.map(entry => {
      const key = keyProps.map(k => entry[k]).join('|');
      return [key, entry];
      });
      const map = new Map(kvArray);
      return Array.from(map.values());
},
    async discardDraftDataPoint(entityName) {
       this.resetSaveDraftPayload()
      var isDraft = true;
      this.saveDraftDatapointPayload.discardDraft = true;
      var convertedJson = await this.convertJson(isDraft);
      this.$emit("discardDraft", convertedJson,entityName);
    },
    async sendForApproval() {
      this.resetSaveDraftPayload()
      var isDraft = false;
      let datapointsValuesChanged=[]
      let convertedJson = await this.convertJson(isDraft);
      for(let i=0;i<convertedJson.data.length;i++){
        if(convertedJson.data[i].value != convertedJson.data[i].oldvalue || convertedJson.data[i].disseminationdate != convertedJson.data[i].olddisseminationdate || convertedJson.data[i].effectivedate != convertedJson.data[i].oldeffectivedate || convertedJson.data[i].na != convertedJson.data[i].oldna || convertedJson.data[i].note != convertedJson.data[i].oldnote || convertedJson.data[i].isDupicableGroupAdded == true){
          datapointsValuesChanged.push(convertedJson.data[i])
        }
      }
        for(let j=0;j<convertedJson.data.length;j++){
          for(let i=0;i<datapointsValuesChanged.length;i++){
            if(datapointsValuesChanged[i].datapointid == convertedJson.data[j].datapointid){
              convertedJson.data[j]["isDatapointChanged"]=1
            }else{
              if(i == datapointsValuesChanged.length-1){
                if(convertedJson.data[j].isDatapointChanged != 1){
                   convertedJson.data[j]["isDatapointChanged"]=0
                }
              }
            }
        }
      }
      this.$emit("saveAsDraft", convertedJson,true);
    },
    subNavAddEntityWithParentDetails(value) {
      this.$emit("addEntityWithParentDetails", value);
    },
    makingDataPointsEditable() {
      this.$emit("makingDataPointsEditable");
    },
     async convertApprovedJson(group) {
      this.saveDraftDatapointPayload.entityid = this.selectedEntityDataPointGroupDetails.entity.entityid;
      this.saveDraftDatapointPayload.save=true;
      this.saveDraftDatapointPayload.draft = false;
      this.saveDraftDatapointPayload.data = [];
      if(this.taskId != null){
       this.saveDraftDatapointPayload.taskid = this.taskId
       }
      await this.jsonData(group);

      return this.saveDraftDatapointPayload;
    },
    async convertJson(isDraft) {
      this.saveDraftDatapointPayload.entityid = this.selectedEntityDataPointGroupDetails.entity.entityid;
      this.saveDraftDatapointPayload.draft = isDraft;
      this.saveDraftDatapointPayload.data = [];
       if(this.taskId != null){
       this.saveDraftDatapointPayload.taskid = this.taskId
       }
      await this.jsonData(
        this.selectedEntityDataPointGroupDetails.datapointgroups
      );

      return this.saveDraftDatapointPayload;
    },
    showNoteDialog(event, data, note) {
      this.note = note;
      this.triggerByDatapointId = data;
      this.popOverToggle = !this.popOverToggle;
      // event.stopPropagation();
    },
    datapointHistory(dataPoint){
      this.selectedDataPoint = dataPoint
      this.datapointHistoryDetails(dataPoint)
    },
    async datapointHistoryDetails(value){
     this.showLoader = true
      let datapointHistoryResponse = await getDataPointHistory(this.getSelectedEntity.entityid,value.datapointid,value.arrayindex) 
      this.showLoader = false
      if(datapointHistoryResponse.status == 200){
        this.dataPointHistoryData = datapointHistoryResponse.data
         for(var i=0;i<this.dataPointHistoryData.length;i++){
                this.dataPointHistoryData[i]["id"]=this.dataPointHistoryData[i].datapointid
                this.dataPointHistoryData[i]["shownote"] = false
                if(this.dataPointHistoryData[i].validateddate){
                this.dataPointHistoryData[i].validateddate = dateFormatterMixin.methods.convertDate(this.dataPointHistoryData[i].validateddate)
                }
                if(this.dataPointHistoryData[i].changeddate){
                 this.dataPointHistoryData[i].changeddate = dateFormatterMixin.methods.convertDate(this.dataPointHistoryData[i].changeddate)
                }
                if(this.dataPointHistoryData[i].datatype == Enums.dataTypes.Date){
                  if(this.dataPointHistoryData[i].value != "Not Applicable" && this.dataPointHistoryData[i].value != null && this.dataPointHistoryData[i].value.length>0){
                    this.dataPointHistoryData[i].value = dateFormatterMixin.methods.convertAdditionalSearchDate(this.dataPointHistoryData[i].value)
                  }
                   if(this.dataPointHistoryData[i].previousvalue != "Not Applicable" && this.dataPointHistoryData[i].previousvalue != null && this.dataPointHistoryData[i].previousvalue.length>0){
                    this.dataPointHistoryData[i].previousvalue = dateFormatterMixin.methods.convertAdditionalSearchDate(this.dataPointHistoryData[i].previousvalue)
                  }
                  // 
                }
                if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.NeedsApproval || this.dataPointHistoryData[i].validatedvaliditystateid == 0 || this.dataPointHistoryData[i].validatedvaliditystateid == null){
                  this.dataPointHistoryData[i]["status"] = "Pending"
                }else if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.RejectedForEdit){
                   this.dataPointHistoryData[i]["status"] = "Rejected For Edit"
                }else if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.Rejected){
                   this.dataPointHistoryData[i]["status"] = "Rejected"
                }else if(this.dataPointHistoryData[i].validatedvaliditystateid == Enums.ValidityStates.Approved){
                   this.dataPointHistoryData[i]["status"] = "Approved"
                }
            }
        this.showDataPointHistory =true
      }else{
        this.notificationMessage = "Something went wrong";
        this.notificationType="error"
        this.notificationKey = "error-default"
        this.showNotification = true;
      }
    },
    dataPointHistoryModalDismiss(){
       this.showDataPointHistory = false
    },
    listObject(dataPoint,newValue,oldValue,isDupicableGroupAdded){
        let datapointsObjectValueIsArray = {
                ArrayIndex:dataPoint.arrayindex,
                datapointid: dataPoint.datapointid,
                value:newValue,
                oldvalue: oldValue,
                note: dataPoint.note,
                oldnote: dataPoint.oldnote,
                olddisseminationdate:
                dataPoint.olddisseminationdate,
                oldeffectivedate: dataPoint.oldeffectivedate,
                disseminationdate: dataPoint.disseminationdate,
                effectivedate: dataPoint.effectivedate,
                valuedate: null,
                valueinteger: null,
                valuenumber: null,
                valuetime: null,
                na: dataPoint.isna,
                oldna: dataPoint.oldna,
                warning: dataPoint.warning,
                isDupicableGroupAdded:isDupicableGroupAdded
              };
              if(newValue != null && newValue.trim().length < 1){
                datapointsObjectValueIsArray.value = null
                if(oldValue != null && oldValue.trim().length < 1){
                  datapointsObjectValueIsArray.oldvalue = null
                }
              }
              if (dataPoint.effectivedate) {
                datapointsObjectValueIsArray.effectivedate = dateFormatterMixin.methods.dateToUTCWithZeroHours(dataPoint.effectivedate);
              }
              if (dataPoint.disseminationdate) {
                datapointsObjectValueIsArray.disseminationdate = dateFormatterMixin.methods.dateToUTCWithZeroHours(dataPoint.disseminationdate)
              }

              // If dataTypeId is IdInteger so need to pass "valueinteger","valuenumber" in int format but not in string
              if(dataPoint.datatypeid == this.dataTypes.IdInteger && newValue != null){
                  datapointsObjectValueIsArray.valueinteger = parseInt(newValue)
                  datapointsObjectValueIsArray.valuenumber = parseInt(newValue)
              }
              if(dataPoint.datatypeenum == this.dataTypes.IdInteger && newValue != null){
                datapointsObjectValueIsArray.valueinteger = parseInt(newValue)
                datapointsObjectValueIsArray.valuenumber = parseInt(newValue)
              }

              return datapointsObjectValueIsArray
    },
    async jsonData(dataGroup) {
      for (let group of dataGroup) {
          let isDupicableGroupAdded = false
          if(group.groupAction=="add"){
            isDupicableGroupAdded = true
          }
        for (var j = 0; j < group.datapointslist.length; j++) {
          if(!group.datapointslist[j].isautomapped){
          if (
            group.datapointslist[j].values != null &&
            group.datapointslist[j].values.constructor === Array
          ) {
            if(group.datapointslist[j].oldvalue != null && group.datapointslist[j].oldValues != null){
            var equalValues=[];
            var oldValues=[];
            if(group.datapointslist[j].oldValues){
              oldValues=group.datapointslist[j].oldValues
              equalValues = group.datapointslist[j].values.filter(element => group.datapointslist[j].oldValues.includes(element));
            }
            if(group.datapointslist[j].oldvalue){
               oldValues.push(group.datapointslist[j].oldvalue)
               group.datapointslist[j].oldValues = group.datapointslist[j].oldValues.filter((x, i, a) => a.indexOf(x) === i)
               equalValues = group.datapointslist[j].values.filter(element => group.datapointslist[j].oldvalue.includes(element));
            }
            
           if(equalValues.length > 0){
             for(var e=0;e<equalValues.length;e++){
              await this.saveDraftDatapointPayload.data.push(
                 this.listObject(group.datapointslist[j],equalValues[e],equalValues[e],isDupicableGroupAdded)
              );
              
             }
             
              const oldValuesNotInValues = oldValues.filter(element => !equalValues.includes(element));
                
              const ValuesNotInoldValues = group.datapointslist[j].values.filter(element => !equalValues.includes(element));
                if(ValuesNotInoldValues.length > oldValuesNotInValues.length){
                  for(var a=0;a<ValuesNotInoldValues.length;a++){
                    if(a < oldValuesNotInValues.length){
                    await  this.saveDraftDatapointPayload.data.push(this.listObject(group.datapointslist[j],ValuesNotInoldValues[a],oldValuesNotInValues[a],isDupicableGroupAdded))
                    }else{
                     await this.saveDraftDatapointPayload.data.push(this.listObject(group.datapointslist[j],ValuesNotInoldValues[a],null,isDupicableGroupAdded))
                    }
                  }
                }else{
                  for(var b=0;b<oldValuesNotInValues.length;b++){
                    if(b < ValuesNotInoldValues.length){
                    await  this.saveDraftDatapointPayload.data.push(this.listObject(group.datapointslist[j],ValuesNotInoldValues[b],oldValuesNotInValues[b],isDupicableGroupAdded))
                    }else{
                     await this.saveDraftDatapointPayload.data.push(this.listObject(group.datapointslist[j],null,oldValuesNotInValues[b],isDupicableGroupAdded))
                    }
                  }
                }
                
           }else{
             if(group.datapointslist[j].values.length > group.datapointslist[j].oldValues.length){
                   for(var m=0;m<group.datapointslist[j].values.length;m++){
                    await this.saveDraftDatapointPayload.data.push( this.listObject(group.datapointslist[j],group.datapointslist[j].values[m],group.datapointslist[j].oldValues[m],isDupicableGroupAdded))
                  }
             }else{
               if(group.datapointslist[j].values.length == 0 && group.datapointslist[j].oldValues.length > 0){
                 for(var l=0;l<group.datapointslist[j].oldValues.length;l++){
                   await this.saveDraftDatapointPayload.data.push( this.listObject(group.datapointslist[j],null,group.datapointslist[j].oldValues[l],isDupicableGroupAdded))
                 }
               }else if(group.datapointslist[j].values.length == 0 && group.datapointslist[j].oldValues.length == 0){
                  await this.saveDraftDatapointPayload.data.push( this.listObject(group.datapointslist[j],null,null,isDupicableGroupAdded))
              }else{
               for(var n=0;n<group.datapointslist[j].values.length;n++){
                  await this.saveDraftDatapointPayload.data.push( this.listObject(group.datapointslist[j],group.datapointslist[j].values[n],null,isDupicableGroupAdded))
                  }
               }
             }
             
           }
          }else{
             for(var p=0;p<group.datapointslist[j].values.length;p++){
                    await  this.saveDraftDatapointPayload.data.push(this.listObject(group.datapointslist[j],group.datapointslist[j].values[p],null,isDupicableGroupAdded))
              }
          }
          } else {
            let datapointsObject = {
              ArrayIndex:group.datapointslist[j].arrayindex,
              datapointid: group.datapointslist[j].datapointid,
              value: group.datapointslist[j].value,
              oldvalue: group.datapointslist[j].oldvalue,
              note: group.datapointslist[j].note,
              oldnote: group.datapointslist[j].oldnote,
              olddisseminationdate:
                group.datapointslist[j].olddisseminationdate,
              oldeffectivedate: group.datapointslist[j].oldeffectivedate,
              disseminationdate: group.datapointslist[j].disseminationdate,
              effectivedate: group.datapointslist[j].effectivedate,
              valuedate: null,
              valueinteger: null,
              valuenumber: null,
              valuetime: null,
              na: group.datapointslist[j].isna,
              oldna: group.datapointslist[j].oldna,
              warning: group.datapointslist[j].warning,
              isDupicableGroupAdded:isDupicableGroupAdded
            };
            if (group.datapointslist[j].effectivedate) {
              datapointsObject.effectivedate = dateFormatterMixin.methods.convertAdditionalSearchDate(group.datapointslist[j].effectivedate)
            }
            if (group.datapointslist[j].disseminationdate) {
              datapointsObject.disseminationdate = dateFormatterMixin.methods.convertAdditionalSearchDate(group.datapointslist[j].disseminationdate)
            }
            
           // If dataTypeId is IdInteger so need to pass "valueinteger","valuenumber" in int format but not in string
              if(group.datapointslist[j].datatypeid == this.dataTypes.IdInteger && group.datapointslist[j].value != null){
                  datapointsObject.valueinteger = parseInt( group.datapointslist[j].value)
                  datapointsObject.valuenumber = parseInt( group.datapointslist[j].value)
              }

            if (group.datapointslist[j].datatypeid == this.dataTypes.Time && group.datapointslist[j].value != null) {
              datapointsObject.valuedate=new Date();
              datapointsObject.valuetime = group.datapointslist[j].value;
            } else if (
              group.datapointslist[j].datatypeid == this.dataTypes.Date &&
              group.datapointslist[j].value != null && group.datapointslist[j].value.length > 0
            ) {
              datapointsObject.oldvalue = dateFormatterMixin.methods.convertAdditionalSearchDate(group.datapointslist[j].oldvalue)
              datapointsObject.value = dateFormatterMixin.methods.convertAdditionalSearchDate(group.datapointslist[j].value)
              datapointsObject.valuedate = dateFormatterMixin.methods.dateToUTCWithZeroHours(group.datapointslist[j].value);
            } else if (
              group.datapointslist[j].datatypeid == this.dataTypes.Integer &&
              group.datapointslist[j].value != null
            ) {
              datapointsObject.valueinteger = group.datapointslist[j].value;
            } else if (
              group.datapointslist[j].datatypeid == this.dataTypes.Number &&
              group.datapointslist[j].value != null
            ) {
              if(!isNaN(Math.sign(group.datapointslist[j].value))){
                 datapointsObject.valuenumber = group.datapointslist[j].value;
              }else{
                
                datapointsObject.valuenumber = null
              }
             
            }else if(group.datapointslist[j].datapointname.toString().toUpperCase() == 'MARKET HOLIDAY DATE' ||group.datapointslist[j].datapointname.toString().toUpperCase() == 'KEY DATE'){
              if(group.datapointslist[j].value){
                datapointsObject.value = dateFormatterMixin.methods.dateToUTCWithZeroHours(group.datapointslist[j].value)
                datapointsObject.value = dateFormatterMixin.methods.convertReportingParameterDate(datapointsObject.value)
                datapointsObject.valuedate = dateFormatterMixin.methods.dateToUTCWithZeroHours(group.datapointslist[j].value)
              }
               
            }
           if(group.datapointslist[j].value != null && group.datapointslist[j].value.toString().trim().length < 1){
                datapointsObject.value = null
                if(group.datapointslist[j].oldvalue != null && group.datapointslist[j].oldvalue.toString().trim().length < 1){
                  datapointsObject.oldvalue = null
                }
            }
            if(group.datapointslist[j].note != null && group.datapointslist[j].note.toString().trim().length < 1){
                datapointsObject.note = null
            }
           await this.saveDraftDatapointPayload.data.push(datapointsObject);
          }
        }
        }
        if (group.datapointgroups.length > 0) {
          await this.jsonData(group.datapointgroups);
        }
      }
    },
    getPrimaryBenchmarkDataPointList(orginalData,groupDetails,index,datapointGroupId){
       for (let i = 0; i < orginalData.length; i++) {
         if (orginalData[i].datapointgroupid == datapointGroupId){
           this.addPrimaryBenchMarkTOPrimaryHistory(this.getEntities.datapointgroups, groupDetails,index,orginalData[i].datapointgroups[0])
           break;
       }else{
         if (orginalData[i].datapointgroups.length > 0) {
          this.getPrimaryBenchmarkDataPointList(
            orginalData[i].datapointgroups,groupDetails,index,datapointGroupId);
        }
       }
       }
    },
    async onAddTOBenchmarkHistory(groupDetails,index){
        await this.getPrimaryBenchmarkDataPointList(
        this.getEntities.datapointgroups,
        groupDetails,index,78
      );
    },
    async addPrimaryBenchMarkTOPrimaryHistory(orginalData, groupDetails,index,historyGroup){
      for (let i = 0; i < orginalData.length; i++) {
        if (orginalData[i].datapointgroupid == groupDetails.parentdatapointgroupid) {
           let groupsWithSameId = orginalData[i].datapointgroups.filter((group) => {
          return (group.datapointgroupid == groupDetails.datapointgroupid && group.groupAction != "delete")})
          for(let j=0;j<this.getEntities.datapointgroups.length;j++){
            if(this.getEntities.datapointgroups[j].datapointgroupid == orginalData[i].parentdatapointgroupid){
              for(let k=0;k<this.getEntities.datapointgroups[j].datapointgroups.length;k++){
                if(this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroupdescription == "Primary Benchmark(s) History"){
                  let arrayIndexLength=this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups.length
                  let  existingPrimaryBenchValueGroupDetails = await this.createPrimaryBenchmarkHistoryGroup(historyGroup,groupDetails,arrayIndexLength);
                  
                  if(this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups.length == 1 && this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[0].arrayindex == 0 && this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].groupAction == "update"){
                    for(let i=0;i<this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist.length;i++){
                      if(this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].value != null || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].oldvalue != null ||  this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].effectivedate != null || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].oldeffectivedate != null || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].disseminationdate != null || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].olddisseminationdate != null || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].isna != false || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].oldna != false || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].note != null || this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist[i].oldnote != null){
                        this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups.push(existingPrimaryBenchValueGroupDetails)
                        break;
                      }else{
                        if(i ==  this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups[0].datapointslist.length-1){
                          this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups.splice(0,1)
                          for(let j=0;j<existingPrimaryBenchValueGroupDetails.datapointslist.length;j++){
                            existingPrimaryBenchValueGroupDetails.datapointslist[j].arrayindex=0
                          }
                          this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups.push(existingPrimaryBenchValueGroupDetails)
                        }
                      }
                    }
                  }else{
                    this.getEntities.datapointgroups[j].datapointgroups[k].datapointgroups.push(existingPrimaryBenchValueGroupDetails)
                  }
                }
              }
            }
          }
          if(groupsWithSameId.length > 1){
            // removeGroup
            if(orginalData[i].datapointgroups[index].groupAction == "add"){
              orginalData[i].datapointgroups.splice(index,1)
              let lengthOfGroups = orginalData[i].datapointgroups.filter((group)=>group.datapointgroupid == groupDetails.datapointgroupid)
              for(let j=0;j<lengthOfGroups.length;j++){
                for(let k=0;k<lengthOfGroups[j].datapointslist.length;k++){
                  lengthOfGroups[j].datapointslist[k].arrayindex=j
                }
              }
            }else{
              orginalData[i].datapointgroups[index].groupAction="delete"
              for(let m=0;m<orginalData[i].datapointgroups[index].datapointslist.length;m++){
                      orginalData[i].datapointgroups[index].datapointslist[m].arrayindex=-1
                      orginalData[i].datapointgroups[index].datapointslist[m].disseminationdate= null
                      orginalData[i].datapointgroups[index].datapointslist[m].disseminationdatestring= null
                      orginalData[i].datapointgroups[index].datapointslist[m].effectivedate= null
                      orginalData[i].datapointgroups[index].datapointslist[m].effectivedatestring= null
                      orginalData[i].datapointgroups[index].datapointslist[m].isna=false
                      orginalData[i].datapointgroups[index].datapointslist[m].note=null
                      orginalData[i].datapointgroups[index].datapointslist[m].value= null
              }
            }
          }else{
                for(let m=0;m<groupDetails.datapointslist.length;m++){
                      groupDetails.datapointslist[m].arrayindex=0
                      groupDetails.datapointslist[m].disseminationdate= null
                      groupDetails.datapointslist[m].disseminationdatestring= null
                      groupDetails.datapointslist[m].effectivedate= null
                      groupDetails.datapointslist[m].effectivedatestring= null
                      groupDetails.datapointslist[m].isna=false
                      groupDetails.datapointslist[m].note=null
                      groupDetails.datapointslist[m].value= null
              }
              this.multiSelectRefKey +=1
              this.multiSelectEnumKey+=1
              this.selectRefKey+=1
              this.selectEnumKeySec+=1
              this.selectEnumKey+=1
          }
          break;
        }else{
          if (orginalData[i].datapointgroups.length > 0) {
          this.addPrimaryBenchMarkTOPrimaryHistory(
            orginalData[i].datapointgroups,
            groupDetails,index,historyGroup
          );
        }
        }
      }
    },

    updateDropDownList() {
      this.selectRefKey++;
    },
    
     async addAnotherTOSelectedEntity(orginalData, groupDetails,index) {
      for (let i = 0; i < orginalData.length; i++) {
        if (
          orginalData[i].datapointgroupid == groupDetails.parentdatapointgroupid
        ) {
          let lengthOfGroups = orginalData[i].datapointgroups.filter((group)=>group.datapointgroupid == groupDetails.datapointgroupid)
          let arrayIndexLength=lengthOfGroups.length
           var  addAnotherExistingGroupDetails =await this.createAnotherExistingGroup(groupDetails,true,arrayIndexLength)

           index +=1
           orginalData[i].datapointgroups.splice(index,0,addAnotherExistingGroupDetails)
           this.multiSelectRefKey +=1
            this.multiSelectEnumKey+=1
            this.selectRefKey+=1
            this.selectEnumKeySec+=1
            this.selectEnumKey+=1
          break;
        }else{
          if (orginalData[i].datapointgroups.length > 0) {
          this.addAnotherTOSelectedEntity(
            orginalData[i].datapointgroups,
            groupDetails,index
          );
        }
        }
        
      }
    },
    createPrimaryBenchmarkHistoryGroup(historyGroup,groupDetails,arrayIndexLength){
      let historyGroupDatapointList = historyGroup.datapointslist
      let addPrimaryHistoryGroupDetails=
      {
        arrayindex:historyGroup.arrayindex,
        datapointgroupdescription:historyGroup.datapointgroupdescription,
        datapointgroupid:historyGroup.datapointgroupid,
        datapointgroups:historyGroup.datapointgroups,
        datapointgrouptypeid:historyGroup.datapointgrouptypeid,
        datapointslist:[],
        hidden:historyGroup.hidden,
        parentdatapointgroupid:historyGroup.parentdatapointgroupid,
        sortorder:historyGroup.sortorder,
        type:historyGroup.type,
         groupAction:"add"
      }
      for(let i=0;i<historyGroupDatapointList.length;i++){
        let datapointListObject={
                arrayindex: arrayIndexLength,
                arraylength: historyGroupDatapointList[i].arraylength,
                countryid: historyGroupDatapointList[i].countryid,
                datapointdependencytypeenum: historyGroupDatapointList[i].datapointdependencytypeenum,
                datapointdependencytypeid: historyGroupDatapointList[i].datapointdependencytypeid,
                datapointdescription:historyGroupDatapointList[i].datapointdescription,
                datapointexportname: historyGroupDatapointList[i].datapointexportname,
                datapointgroupid: historyGroupDatapointList[i].datapointgroupid,
                datapointgroupname: historyGroupDatapointList[i].datapointgroupname,
                datapointgrouptype: historyGroupDatapointList[i].datapointgrouptype,
                datapointgrouptypeid: historyGroupDatapointList[i].datapointgrouptypeid,
                datapointid: historyGroupDatapointList[i].datapointid,
                datapointname:historyGroupDatapointList[i].datapointname,
                datapointvalueid:historyGroupDatapointList[i].datapointvalueid,
                datatypedescription: historyGroupDatapointList[i].datatypedescription,
                datatypeenum:historyGroupDatapointList[i].datatypeenum,
                datatypeid:historyGroupDatapointList[i].datatypeid,
                disabled: historyGroupDatapointList[i].disabled,
                disabledasdefault: historyGroupDatapointList[i].disabledasdefault,
                disseminationdate: null,
                disseminationdatestring: null,
                effectivedate: null,
                effectivedateisrequired: historyGroupDatapointList[i].effectivedateisrequired,
                effectivedatestring: null,
                entitytypeid: historyGroupDatapointList[i].entitytypeid,
                enumid: historyGroupDatapointList[i].enumid,
                inputternote: historyGroupDatapointList[i].inputternote,
                isautomapped: historyGroupDatapointList[i].isautomapped,
                isna: false,
                isrequired: historyGroupDatapointList[i].isrequired,
                languageid: historyGroupDatapointList[i].languageid,
                note: historyGroupDatapointList[i].note,
                olddisseminationdate:null,
                oldeffectivedate:null,
                oldna: false,
                oldnote: historyGroupDatapointList[i].oldnote,
                oldvalue: null,
                referencedentitytypeid: historyGroupDatapointList[i].referencedentitytypeid,
                showinfilter: historyGroupDatapointList[i].showinfilter,
                shownacheckbox: historyGroupDatapointList[i].shownacheckbox,
                shownote: historyGroupDatapointList[i].shownote,
                sortorder:historyGroupDatapointList[i].sortorder,
                userid: historyGroupDatapointList[i].userid,
                validitystate:null,
                validitystatecss: null,
                validitystatedate: null,
                validitystateid:null,
                validitystatestring: null,
                value: null,
                warningonly: historyGroupDatapointList[i].warningonly,
              }
              for(let j=0;j<this.enums.primaryBenchmarkDataIdMap.length;j++){
                if(this.enums.primaryBenchmarkDataIdMap[j].PreviousId == historyGroupDatapointList[i].datapointid){
                  for(let k=0;k<groupDetails.datapointslist.length;k++){
                    if(this.enums.primaryBenchmarkDataIdMap[j].CurrentId == groupDetails.datapointslist[k].datapointid){
                      datapointListObject.value = groupDetails.datapointslist[k].value
                      datapointListObject.disseminationdate = groupDetails.datapointslist[k].disseminationdate
                      datapointListObject.effectivedate = groupDetails.datapointslist[k].effectivedate
                      datapointListObject.isna = groupDetails.datapointslist[k].isna
                      datapointListObject.note = groupDetails.datapointslist[k].note
                    }
                  }
                }
              }
                  if(historyGroupDatapointList[i].datapointid == this.enums.PreviousPrimaryBenchmarkFundStartdateDatapointId){
                    for(let k=0;k<groupDetails.datapointslist.length;k++){
                    if(groupDetails.datapointslist[k].datapointid == this.enums.PreviousPrimaryBenchmarkFundDatapointId){
                      datapointListObject.value = groupDetails.datapointslist[k].effectivedate
                    }
                    }
                  }
              addPrimaryHistoryGroupDetails.datapointslist.push(datapointListObject)
      }
      return addPrimaryHistoryGroupDetails
    },
    createAnotherExistingGroup(groupDetails,isValuesNull,arrayIndexLength){
      let addGroupDetails=
      {
        arrayindex:null,
        datapointgroupdescription:'',
        datapointgroupid:undefined,
        datapointgroups:[],
        datapointgrouptypeid:undefined,
        datapointslist:[],
        hidden:false,
        parentdatapointgroupid:undefined,
        sortorder:null,
        type:undefined,
        groupAction:"add"
      }
      for(let d=0;d<groupDetails.datapointslist.length;d++){
              var datapointObject={
                arrayindex: arrayIndexLength,
                arraylength: groupDetails.datapointslist[d].arraylength,
                countryid: groupDetails.datapointslist[d].countryid,
                datapointdependencytypeenum: groupDetails.datapointslist[d].datapointdependencytypeenum,
                datapointdependencytypeid: groupDetails.datapointslist[d].datapointdependencytypeid,
                datapointdescription:groupDetails.datapointslist[d].datapointdescription,
                datapointexportname: groupDetails.datapointslist[d].datapointexportname,
                datapointgroupid: groupDetails.datapointslist[d].datapointgroupid,
                datapointgroupname: groupDetails.datapointslist[d].datapointgroupname,
                datapointgrouptype: groupDetails.datapointslist[d].datapointgrouptype,
                datapointgrouptypeid: groupDetails.datapointslist[d].datapointgrouptypeid,
                datapointid: groupDetails.datapointslist[d].datapointid,
                datapointname:groupDetails.datapointslist[d].datapointname,
                datapointvalueid:groupDetails.datapointslist[d].datapointvalueid,
                datatypedescription: groupDetails.datapointslist[d].datatypedescription,
                datatypeenum:groupDetails.datapointslist[d].datatypeenum,
                datatypeid:groupDetails.datapointslist[d].datatypeid,
                disabled: groupDetails.datapointslist[d].disabled,
                disabledasdefault: groupDetails.datapointslist[d].disabledasdefault,
                disseminationdate: null,
                disseminationdatestring: null,
                effectivedate: null,
                effectivedateisrequired: groupDetails.datapointslist[d].effectivedateisrequired,
                effectivedatestring: null,
                entitytypeid: groupDetails.datapointslist[d].entitytypeid,
                enumid: groupDetails.datapointslist[d].enumid,
                inputternote: groupDetails.datapointslist[d].inputternote,
                isautomapped: groupDetails.datapointslist[d].isautomapped,
                isna: false,
                isrequired: groupDetails.datapointslist[d].isrequired,
                languageid: groupDetails.datapointslist[d].languageid,
                note: groupDetails.datapointslist[d].note,
                olddisseminationdate:null,
                oldeffectivedate:null,
                oldna: false,
                oldnote: groupDetails.datapointslist[d].oldnote,
                oldvalue: null,
                referencedentitytypeid: groupDetails.datapointslist[d].referencedentitytypeid,
                showhistory:false,
                showinfilter: groupDetails.datapointslist[d].showinfilter,
                shownacheckbox: groupDetails.datapointslist[d].shownacheckbox,
                shownote: groupDetails.datapointslist[d].shownote,
                sortorder:groupDetails.datapointslist[d].sortorder,
                userid: groupDetails.datapointslist[d].userid,
                validitystate:null,
                validitystatecss: null,
                validitystatedate: null,
                validitystateid:null,
                validitystatestring: null,
                value: null,
                warningonly: groupDetails.datapointslist[d].warningonly,
              }
              if(!isValuesNull){
                datapointObject.value=groupDetails.datapointslist[d].value
                datapointObject.disseminationdate=groupDetails.datapointslist[d].disseminationdate
                datapointObject.disseminationdatestring=groupDetails.datapointslist[d].disseminationdatestring
                datapointObject.effectivedate=groupDetails.datapointslist[d].effectivedate
                datapointObject.effectivedatestring=groupDetails.datapointslist[d].effectivedatestring
              }
              addGroupDetails.datapointslist.push(datapointObject)
            }
            addGroupDetails.arrayindex = groupDetails.arrayindex
            addGroupDetails.datapointgroupdescription=groupDetails.datapointgroupdescription
            addGroupDetails.datapointgroupid=groupDetails.datapointgroupid
            addGroupDetails.datapointgrouptypeid = groupDetails.datapointgrouptypeid
            addGroupDetails.datapointgroups=groupDetails.datapointgroups
            addGroupDetails.hidden=groupDetails.hidden
            addGroupDetails.parentdatapointgroupid=groupDetails.parentdatapointgroupid
            addGroupDetails.sortorder=groupDetails.sortorder
            addGroupDetails.type=groupDetails.type
            return addGroupDetails
    },
    
  },
};
</script>
<style   lang="scss" scoped>
  @import "../../assets/css/styles.scss";
#data-point-details::v-deep  .mds-section___VueMDS3Demo.mds-section--collapsible___VueMDS3Demo.mds-section--expanded___VueMDS3Demo>.mds-section__header-container___VueMDS3Demo .mds-section__toggle___VueMDS3Demo {
  transform: rotate(90deg);
}

#data-point-details::v-deep  .mds-section___VueMDS3Demo.mds-section--collapsible___VueMDS3Demo>.mds-section__header-container___VueMDS3Demo .mds-section__toggle___VueMDS3Demo {
  transform: rotate(0deg);
}
.pendingApprovalIcon{
   @include mds-icon-color(#ef7622);
}
.exclaimIcon{
   @include mds-icon-color(#ff0000);
}
p {
  margin: 0;
}
.disabled {
  // pointer-events: none;
  // cursor: not-allowed;
  // color: gray;
}

.noteIdicator{
 height: 10px; 
  width: 10px; 
  background-color: #0077cf; 
  border-radius: 50%; 
  display: inline-block; 
  margin-left: 15px;
  position: absolute;
}
.dataPointSubLabel{
@include mds-eyebrow-heading();

}
#data-point-details::v-deep  .notePopOver.mds-section___VueMDS3Demo .mds-section__actions___VueMDS3Demo{
  margin-left: 0% !important;
  justify-content: center;
  align-items: center;
}
#data-point-details::v-deep .actionButton{
  padding:0 ;
  margin-left: 16px!important;
  color: #5E5E5E;
  border-bottom: 1px solid #5E5E5E;
}
#select-drop-down::v-deep .single-select-add-new-modal .mds-section___VueMDS3Demo .mds-section__actions___VueMDS3Demo{
  margin-left: auto !important;
  justify-content: center;
}
#multi-select-drop-down::v-deep .multi-select-add-new-modal .mds-section___VueMDS3Demo .mds-section__actions___VueMDS3Demo{
  margin-left: auto !important;
  justify-content: center;
}
#printThis::v-deep .data-point-history-modal .mds-section___VueMDS3Demo .mds-section__actions___VueMDS3Demo{
  margin-left: auto !important;
  justify-content: center;
}
#auto-mapped-popup::v-deep .mds-section___VueMDS3Demo.mds-section--collapsible___VueMDS3Demo.mds-section--expanded___VueMDS3Demo .mds-section__header-container___VueMDS3Demo{
  margin-bottom: 0px!important;
}

</style>
