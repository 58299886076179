<template>
<div id="auto-mapped-popup">
   <mds-modal class="targetDatapointModal" v-model="showAutoMappedPopup" title="Auto Mapped datapoints" :width="'600px'" action-required>
        <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
                <mds-button @click="onCancelClick" variation="secondary">Cancel</mds-button>
                <mds-button @click="onConfirmClick" variation="primary">Ok</mds-button>
            </mds-button-container>
      </template>
      <p style="margin-bottom:16px;">This is auto mapped datapoint on changing value it will auto update the target datapoint values</p>
      <mds-table>
      <mds-thead>
          <mds-th v-for="(header, index) in targetDatapointHeader" :key="index"
          > {{header.text}} </mds-th>
      </mds-thead>
      <mds-tbody>
          <template 
              v-for="(row, index) in targetDatapointDetails">
          <mds-tr v-if="row.datapointname != null" :key="index">
              <mds-td
                  v-for="(header, index) in targetDatapointHeader"
                  :key="index"
              > {{ row[header.fieldName] }} </mds-td>
          </mds-tr>
          </template>
          
      </mds-tbody>
</mds-table>
</mds-modal>
</div>

</template>
<script>
import MdsModal from '@mds/modal';
import { MdsButtonContainer,MdsButton } from "@mds/button";
import { MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd } from '@mds/data-table-4.0.2';
export default {
    components:{
       MdsButtonContainer,MdsButton,
       MdsModal,
       MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd
    },
      computed: {
    iconColorClass() {
        if(this.iconColor == 'red'){
            return 'dialogIconColorRed'
        }else if(this.iconColor == 'blue'){
            return 'dialogIconColorBlue'
        }else{
             return 'dialogIconColorBlack';
        }
    }
  },
    data(){
        return{
            targetDatapointHeader:undefined,
            targetDatapointDetails:undefined,
            showAutoMappedPopup:false,
            resolvePromise: undefined,
            rejectPromise: undefined,
        }
    },
    methods:{
         show(opts = {}) {
             this.targetDatapointHeader=opts.targetDatapointHeader
             this.targetDatapointDetails=opts.targetDatapointDetails
             this.openDialog()
              return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
         },
         openDialog(){
             this.showAutoMappedPopup=true
         },
        onCancelClick(){
            this.showAutoMappedPopup = false
            this.resolvePromise(false)
        },
        onConfirmClick(){
            this.showAutoMappedPopup = false
            this.resolvePromise(true)
        }
    }
    
}
</script>
<style lang="scss" scoped>
  @import "../../assets/css/styles.scss";
#auto-mapped-popup::v-deep .mds-section___VueMDS3Demo.mds-section--collapsible___VueMDS3Demo.mds-section--expanded___VueMDS3Demo .mds-section__header-container___VueMDS3Demo{
  margin-bottom: 0px!important;
}
//   .mds-dialog__actions___VueMDS3Demo .mds-dialog__actions-right___VueMDS3Demo
</style>