<template>
    <div>
         <mds-search-field :placeholder="searchPlaceHolder" label="Search" ></mds-search-field>
    </div>
</template>
<script>
import MdsSearchField from '@mds/search-field';

export default {
    components:{
        MdsSearchField
    },
    props:{
        searchPlaceHolder:String
    }
}
</script>