<template>
  <div>
    <div class="editAreaHeader">
      <div style="width:100%">
        <mds-layout-grid>
          <mds-row>
            <mds-col :cols="7">
              <div style="display:flex;align-items: center;width:100%;">
                <span style="overflow: hidden;max-width: 50%;">
                  <h4 class="sub-heading" style="margin:0;" :title="getSelectedEntity.entityname">
                    {{ getSelectedEntity.entityname }}
                  </h4>
                </span>
                <span class="health-status">
                  </span>
                  <mds-tag v-if="getSelectedEntity.isdraft" style="margin-left:2%">
                    Draft
                </mds-tag>
                 <mds-tag v-if="getSelectedEntity.obsolete" style="margin-left:2%">
                    Closed
                </mds-tag>
              </div>
            </mds-col>
            <mds-col :cols="5" v-if="!getSelectedEntity.obsolete">
              <div
                style="display:flex;align-items: center;width:100%;height: 100%;justify-content: flex-end;"
              >
                <div style="margin-right:2%" v-if="buttonNameDetails">
                    <mds-button-container v-if="!isApproveDatapointsRoute">
                      <mds-button
                        @click="onEditExternalIdentifiersClick()"
                        icon="pencil"
                        variation="flat"
                        style="white-space: nowrap; float: right"
                        type="button"
                        :disabled="!(permissions.includes('add-investment-add-entity-with-parent') || permissions.includes('*'))"
                      >Edit External Identifiers
                      </mds-button>
                      <mds-button
                        @click="addEntityWithParentDetails()"
                        icon="plus"
                        variation="secondary"
                        style="white-space: nowrap; float: right"
                        :disabled="!(permissions.includes('add-investment-add-entity-with-parent') || permissions.includes('*'))"
                        type="button"
                      >{{ buttonNameDetails }}
                      </mds-button>
                    </mds-button-container>
                </div>
                <mds-button-container v-if="!buttonNameDetails">
                      <mds-button
                        @click="onEditExternalIdentifiersClick()"
                        icon="pencil"
                        variation="flat"
                        style="white-space: nowrap; float: right;margin-right: 16px;"
                        type="button"
                        :disabled="!(permissions.includes('add-investment-add-entity-with-parent') || permissions.includes('*'))"
                      >Edit External Identifiers
                      </mds-button>
                </mds-button-container>
                <div style="float: right;">
                  <!-- <mds-button-container  v-if="!getSelectedEntity.isdraft && !isApproveDatapointsRoute">
                     <button-component
                      v-if="inputsReadOnly"
                      :onClick="makeEditAble"
                      buttonName="Edit"
                      buttonVariation="secondary"
                      style="float:right"
                    >
                    </button-component>
                  </mds-button-container> -->
                    <mds-button-container  v-if="isApproveDatapointsRoute">
                    <mds-button
                      @click="onSendForApprovalClick()"
                      variation="primary"
                      style="white-space: nowrap; float: right"
                      :disabled="!(permissions.includes('add-investment-send-for-approval') || permissions.includes('*'))"
                      type="button"
                    >Send for Approval
                    </mds-button>
                  </mds-button-container>
                  <mds-button-container  v-if="!isApproveDatapointsRoute">
                    <mds-button
                      v-if="getSelectedEntity.isdraft"
                      @click="discardDraft()"
                      icon="trash"
                      variation="icon-only"
                      style="white-space: nowrap; float: right"
                      type="button"
                      :disabled="!(permissions.includes('add-investment-discard-draft') || permissions.includes('*'))"
                    >Discard Changes
                    </mds-button>
                    
                    <mds-button
                      v-if="(inputsReadOnly && getSelectedEntity.isapproved ) || (inputsReadOnly && getSelectedEntity.isdraft)"
                      @click="makeEditAble()"
                      variation="secondary"
                      style="white-space: nowrap; float: right"
                      type="button"
                      :disabled="!(permissions.includes('add-investment-make-editable') || permissions.includes('*'))"
                    >Edit
                    </mds-button>
                    
                    <mds-button
                      v-if="!inputsReadOnly && !getSelectedEntity.isapproved"
                      @click="onSaveClick()"
                      variation="secondary"
                      style="white-space: nowrap; float: right"
                      type="button"
                      :disabled="!(permissions.includes('add-investment-save') || permissions.includes('*'))"
                    >Save as Draft
                    </mds-button>

                    <mds-button
                      v-if="( getSelectedEntity.isdraft && !inputsReadOnly ) || ( getSelectedEntity.isdraft && inputsReadOnly )"
                      @click="onSendForApprovalClick()"
                      variation="primary"
                      style="white-space: nowrap; float: right"
                      type="button"
                      :disabled="!(permissions.includes('add-investment-send-for-approval-draft') || permissions.includes('*'))"
                    >Send for Approval
                    </mds-button>

                    <mds-button
                      v-if="!getSelectedEntity.isdraft && getSelectedEntity.isapproved && !inputsReadOnly"
                      @click="onSendForApprovalClick()"
                      variation="primary"
                      style="white-space: nowrap; float: right"
                      type="button"
                      :disabled="!(permissions.includes('add-investment-send-for-approval-approved') || permissions.includes('*'))"
                    >Send for Approval
                    </mds-button>
                  </mds-button-container>
                </div>
              </div>
            </mds-col>
          </mds-row>
        </mds-layout-grid>
      </div>
    </div>
    <div class="jumpToDiv"  v-if="getEntities.datapointgroups != undefined && getEntities.datapointgroups.length > 0">
          <div style="width:70%;">
            <mds-layout-grid>
              <mds-row style="margin:0">
                <mds-col :cols="2" style="padding:0;"> 
                  <h5 style="margin:0;text-align:center;">Jump to:</h5>
                </mds-col>
                <mds-col :cols="10" style="padding-left:0;">
                  <ul>
                    <li v-for="dataGroup in getEntities.datapointgroups" :key="dataGroup.datapointgroupid">
                      <a class="jumpToList" @click="jumpToSection(dataGroup.datapointgroupid)"
                >
                      <h4 class="jumpHeads" >{{dataGroup.datapointgroupdescription}}<mds-icon name="caret-down"  size="small"></mds-icon></h4>
                      </a></li>
                  </ul>

                </mds-col>
              </mds-row>
            </mds-layout-grid>

          </div>
          <div style="width:30%;">
            <mds-layout-grid>
            <mds-row>
              <mds-col >
                <div class="jumpToLanguageDiv">
                  <h4 style="margin:0;margin-right:2%">Languages:</h4>

                <mds-select
                class="selectLanguage"
                  hidden-label
                  label="Default"
                  @change="languageChange"
                  v-model="selectedLanguage"
                >
                <option >Select Language</option>
                <option v-for="(language,index) in getLanguages" :key="index" :value="language.value">
                  {{language.text}}
                </option>
                </mds-select>

                </div>
                
              </mds-col>
            </mds-row>
          </mds-layout-grid>

          </div>
    </div>
   
  </div>
</template>
<script>
import { mapGetters} from "vuex";
import { MdsLayoutGrid, MdsRow, MdsCol } from "@mds/layout-grid";
import { MdsButtonContainer } from "@mds/button";
import { MdsButton } from "@mds/button";
import MdsIcon from "@mds/icon";
import MdsSelect from "@mds/select";
import { MdsTag } from '@mds/tag'
export default {
  components: {
    MdsSelect,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButtonContainer,
    MdsButton,
    MdsIcon,
    MdsTag
  },
  props: {
    isApproveDatapointsRoute:{
       type: Boolean,
    },
    addEntityButtonName: {
      type: String,
    },
    inputsReadOnly: {
      type: Boolean,
    },
    actionDetails: {
      type: Array,
    },
    addInvestmentScreen: {
      type: Boolean,
    },
  },
  data() {
    return {
      buttonNameDetails:'',
      selectedLanguage:'',
      saveDraftDatapointPayload: {
        entityid: 0,
        languageid: "en",
        countryid: null,
        draft: true,
        discardDraft: false,
        data: [],
        
      },
    };
  },
  computed: {
    ...mapGetters("languages", [
      "getLanguages",
      "getSelectedLanguage"
    ]),
    ...mapGetters("entity", [
      "getEntities",
      "getSelectedEntity",
      "getDatapointJson",
      "getEntityTypes"
    ]),
    permissions () {
      return this.$store.state.auth.permissions;
    }
  },
  mounted() {
    this.selectedLanguage = this.getSelectedLanguage
    if(this.getSelectedEntity){
      if(this.getEntityTypes.length > 0){
         let childEntityTypeDetails = this.getEntityTypes.filter(entityType=>entityType.entitytypeid == this.getSelectedEntity.entitytypeid)
        if(childEntityTypeDetails[0].childentitytypedescription != null && childEntityTypeDetails[0].childentityid != 0 ){
          this.buttonNameDetails = "Add "+childEntityTypeDetails[0].childentitytypedescription
        }
      }
    }
    
  },
  methods: {
    onEditExternalIdentifiersClick(){
            this.$emit("editExternalIdentifiers")
    },
    jumpToSection(sectionId) {
      this.$emit("jumpToSection", sectionId);
    },

    showApprovalButton(){
      if(this.getSelectedEntity.isdraft == true){
        return true
      }else{
        return false
      }
    },
    addEntityWithParentDetails() {
      this.$emit("subNavAddEntityWithParentDetails", this.getSelectedEntity);
    },
   async onSendForApprovalClick() {
      this.$emit("sendForApproval")
    },
    makeEditAble() {
      this.$emit("makingDataPointsEditable");
    },
    async onSaveClick() {
      this.$emit("saveDataPoint");
    },
    async discardDraft(){
      this.$emit("discardDraft",this.getSelectedEntity.entityname);
    },
    convertJson(isDraft){
      this.saveDraftDatapointPayload.entityid = this.getDatapointJson.entity.entityid;
      this.saveDraftDatapointPayload.draft = isDraft
      this.saveDraftDatapointPayload.data = [];
      for (var i = 0; i < this.getDatapointJson.datapointgroups.length; i++) {
        for (
          var j = 0;
          j < this.getDatapointJson.datapointgroups[i].datapointslist.length;
          j++
        ) {
          var datapointsObject = {
            datapointid: this.getDatapointJson.datapointgroups[i]
              .datapointslist[j].datapointid,
            value: this.getDatapointJson.datapointgroups[i].datapointslist[j]
              .value,
            oldvalue: this.getDatapointJson.datapointgroups[i].datapointslist[j]
              .oldvalue,
            note: this.getDatapointJson.datapointgroups[i].datapointslist[j]
              .note,
            oldnote: this.getDatapointJson.datapointgroups[i].datapointslist[j]
              .oldnote,
            olddisseminationdate: this.getDatapointJson.datapointgroups[i]
              .datapointslist[j].olddisseminationdate,
            oldeffectivedate: this.getDatapointJson.datapointgroups[i]
              .datapointslist[j].oldeffectivedate,
            disseminationdate: this.getDatapointJson.datapointgroups[i]
              .datapointslist[j].disseminationdate,
            effectivedate: this.getDatapointJson.datapointgroups[i]
              .datapointslist[j].effectivedate,
            valuedate: null,
            valueinteger: null,
            valuenumber: null,
            valuetime: null,
            na: this.getDatapointJson.datapointgroups[i].datapointslist[j].isna,
            oldna: this.getDatapointJson.datapointgroups[i].datapointslist[j]
              .oldna,
            warning: false,
          };

          this.saveDraftDatapointPayload.data.push(datapointsObject);
        }
      }
      
      return this.saveDraftDatapointPayload
    },
    languageChange(){
      this.$emit("changeLanguage",this.selectedLanguage)
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/css/styles.scss";
.jumpToDiv{
width:100%;background:#f2f2f2;display: flex;
    align-items: center;padding:1% 0;border-top:1px solid rgba(0,0,0,0.2);
}
.sub-heading {
  @include mds-level-5-heading($bold: true);
  color: $mds-text-color-primary;
  overflow: hidden;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.editAreaHeader {
  margin-bottom: 1%;
}
.jumpHeads {
  text-decoration-line: underline;
  font-weight: normal;
  display: flex;
  margin: 0;
  margin-left: 0;
  align-items: center;
 
}
.jumpToList{
  color: black !important;
  text-decoration: none !important;
}
 .jumpToLanguageDiv{
display:flex;justify-content: flex-end;
    margin-right: 5%;align-items: center;
    .selectLanguage{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
ul{
  margin: 0;
  padding: 0;
}
li{
list-style: none;
    display: inline-block;
    margin: 0 5px;  
    cursor: pointer;
}
.health-status{
 height: 10px; 
  width: 10px; 
  background-color: green; 
  border-radius: 50%; 
  display: inline-block; 
  margin-left:2%;
}
</style>
