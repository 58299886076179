<template>
<div id="select-drop-down">
<mds-select  :key="selectDropDown"  hidden-label label="--" v-model="selectedValue" @change="change();isError=false" :disabled='isdisabled || isDisabledOnNa' :error="isError" 
      :error-text="errorMessage">
            <option value="">--</option>
            <option value="addNew" v-if="type == 'selectForReference' && checkToShowAddButton()">+ Add new</option>
            <option v-for="(option, index) in dropDownList" 
                    :key="index" :value="option.value" 
                    :selected="selectedOption(option)"> 
                    {{ option.text }}
            </option>
        </mds-select>
        <div class="single-select-add-new-modal">
          <mds-modal action-required :title="'Add '+datapointName" :width="'600px'" v-model="toggleAddNewOption">
            <div style="width:70%;margin-bottom: 16px;">
              <mds-input :error="validationError" :error-text="validationMessages"  v-model="addNewValue" label="Name"></mds-input>
            </div>
            <template v-slot:mds-modal-actions>
                <mds-button-container right-aligned>
                  <button-component  
                            :onClick="cancelClick"
                            buttonName="Cancel"
                            buttonVariation="secondary"
                            ></button-component>
                            <button-component  
                            :onClick="addClick"
                            buttonName="Add"
                            buttonVariation="primary"
                            ></button-component>
                </mds-button-container>
            </template>
           </mds-modal>
        </div>
        <notification-component
          v-if="showNotification"
          :notificationMessage="notificationMessage"
          :notificationType="notificationType"
          :keyType="notificationKey"
          :dismissDelay="5000"
          @close="showNotification = false"
        ></notification-component>
</div>

</template>
<script>
import MdsSelect from '@mds/select'
import MdsModal from '@mds/modal'
import MdsInput from '@mds/input'
import {MdsButtonContainer } from '@mds/button'
import {getSelectForEnum,getSelectForReferenceTypeId} from '../../services/add_investment_service'
import ButtonComponent from './ButtonComponent.vue';
import {insertNewEntity} from "../../services/add_investment_service";
import NotificationComponent from "../ui_component/notificationComponent.vue";
import { mapGetters,mapMutations} from "vuex";
export default {
  name:"selectDropDown",
    components:{
        MdsSelect,
        MdsModal,
        MdsInput,
        MdsButtonContainer,
        ButtonComponent,
        NotificationComponent
    },
    props:['value','itemId','valueDetails','oldvalueDetails','type','isdisabled',"isValidationError","errorMessage",'isDisabledOnNa','datapointName'],
    
    data(){
        return{
          selectedValue:'',
          notificationMessage:'',
          notificationType:"error",
          notificationKey: "",
          showNotification:false,
          validationError:false,
          validationMessages:[],
          addNewValue:'',
          toggleAddNewOption:false,
            dropDownList:[],
             selected: null,
             selectedValues:null,
             isError:this.isValidationError,
             selectDropDown:0,
             addEntityPayload: {
                entitytypeid: 0,
                parententitytype: null,
                entitytype: null,
                parententities: null,
                childentities: null,
                childtypes: null,
                entityname: "",
                parententityid: null,
                parententitytypeid: 0,
                parententityname: null,
                entityid: 0,
                iscontadd: "False",
              },
        }
    },
    watch:{
      valueDetails:function(){
       
        this.selectedValues = this.valueDetails
        this.value=this.valueDetails
        if(this.valueDetails == this.oldvalueDetails){
         this.selectDropDown += 1
        }
         
      }
    },
      computed: {
...mapGetters("dropDowns", ["getEnumDropDowns","getReferenceDropDowns"]),
...mapGetters("entity", ["getEntityTypes"]),
  },
    async created(){
      
      this.selectedValues = this.valueDetails
        if(this.selectedValues!=null && this.selectedValues.toString().trim().length==0){
          this.selectedValues = null
        }
        if(this.type == "selectForEnum"){
            // this.dropDownList = await this.getSelectDropDownForEnumId()
            var responseData = await this.getSelectDropDownForEnumId()
          for(let i=0;i<responseData.length;i++){
            var dropDownObject={
              disabled: responseData[i].disabled,
              enumid: responseData[i].enumid,
              selected: responseData[i].selected,
              text: this.trimText(responseData[i].text),
              value: responseData[i].value,
              sortorder:responseData[i].sortorder
            }
            if(responseData[i].value == this.valueDetails){
                dropDownObject.selected=true
              }else{
                 dropDownObject.selected=false
              }
            this.dropDownList.push(dropDownObject)
          }
          
          
          // to sort the items in ascending alphabetical order.
          // this.dropDownList.sort((a, b) => {
          //   return (a.text).localeCompare(b.text);
          // })
          this.dropDownList.sort((a, b) => 
            a.sortorder - b.sortorder
          )
        }else if(this.type == "selectForReference"){
            // this.dropDownList = await this.getSelectedDropDownForRefernceTypeId()
            var responseReferenceData = await this.getSelectDropDownForReferenceId()
            // this.dropDownList = await this.getSelectedDropDownForRefernceTypeId()
            for(let i=0;i<responseReferenceData.length;i++){
            var referenceDopDownObject={
              disabled: responseReferenceData[i].disabled,
              referenceid: responseReferenceData[i].referenceid,
              selected: responseReferenceData[i].selected,
              text: this.trimText(responseReferenceData[i].text),
              value: responseReferenceData[i].value
            }
            if(responseReferenceData[i].value == this.valueDetails){
                referenceDopDownObject.selected=true
               this.$emit("text",responseReferenceData[i].text)
              }else{
                 referenceDopDownObject.selected=false
              }
            this.dropDownList.push(referenceDopDownObject)
          }
        }
    },
    methods:{
        ...mapMutations("dropDowns", ["updateReferenceDropDowns"]),

      checkToShowAddButton(){
        for(let i=0;i<this.getEntityTypes.length;i++){
          if(this.getEntityTypes[i].entitytypeid == this.itemId){
            return false
          }else{
            if(i == this.getEntityTypes.length-1){
              return true
            }
          }
        }
      },
      async  getSelectDropDownForEnumId(){
            if(this.getEnumDropDowns.length != 0){
             for(let i=0;i<this.getEnumDropDowns.length;i++){
               if(this.getEnumDropDowns[i].enumid == this.itemId){
                 return this.getEnumDropDowns[i].values
               }else{
                 if(i == this.getEnumDropDowns.length-1){
                   var response =  await getSelectForEnum(this.itemId,this.selectedValues)
                     return response.data
                 }
               }
             }

           }else{
             var responseData =  await getSelectForEnum(this.itemId,this.selectedValues)
                    return responseData.data
           }
    },
      async  getSelectDropDownForReferenceId(){
           if(this.getReferenceDropDowns.length != 0){
             for(let i=0;i<this.getReferenceDropDowns.length;i++){
               if(this.getReferenceDropDowns[i].refrenceid == this.itemId){
                 return this.getReferenceDropDowns[i].values
               }else{
                 if(i == this.getReferenceDropDowns.length-1){
                   var response =  await getSelectForReferenceTypeId(this.itemId,this.selectedValues)
                     return response.data
                 }
               }
             }

           }else{
             var responseData =  await getSelectForReferenceTypeId(this.itemId,this.selectedValues)
                    return responseData.data
           }
    },
     async  getSelectedDropDownForRefernceTypeId(){
             var response =  await getSelectForReferenceTypeId(this.itemId,this.selectedValues)
              this.updateReferenceDropDowns({id:this.itemId,values:response.data})
     return response.data
    },
    selectedOption(option) {
      if (this.value) {
        return option.selected === true;
      }
      return false;
    },
     change() {
       if(this.selectedValue == "addNew"){
         this.addNewOption()
       }else{
         if(this.selectedValue == ''){
          this.$emit("input", null);
        
            }else{
                const selectedCode = this.selectedValue;
                const selectedList = this.dropDownList.find((list) => {
                  return selectedCode == list.value;
                });
                this.$emit("input", selectedList.value);
                this.$emit("valueChange",selectedList.text)
            }
       }
       
      
    },
    addNewOption(){
      console.log(this.selectedValue);
      this.selectedValue=''
      this.toggleAddNewOption=true
    },
    cancelClick(){
      this.selectedValue = this.oldvalueDetails
      this.toggleAddNewOption=false
       this.selectDropDown += 1
    },
    async addClick(){
      if(this.addNewValue.trim().length == 0){
        this.validationError=true
        this.validationMessages=['The EntityName field is required.']
      }else{
        this.addEntityPayload.entitytypeid=this.itemId
        this.addEntityPayload.entityname=this.addNewValue
        this.toggleAddNewOption=false
         this.$emit("displayLoader",true);
        var response = await insertNewEntity(this.addEntityPayload)
       
        if(!response.isError){
         
          if(response.data.message){
            if(response.data.message.trim().length > 0){
              this.showNotificationBar(response.data.message,"error")
                this.addNewValue=""
                this.selectDropDown += 1
            }
          }else{
          this.selectedValues = response.data.result.entityid
          console.log();
          this.selectedValue =  response.data.result.entityid
          this.dropDownList = await this.getSelectedDropDownForRefernceTypeId()
          
          this.selectDropDown += 1
          this.addNewValue=""
           this.$emit("input", this.selectedValue);
          }
        }else{
          this.$emit("displayLoader",false);
          if(response.data.message){
            if(response.data.message.trim().length > 0){
               this.notificationMessage = response.data.message;
            }else{
               this.notificationMessage ="Something went wrong"
            }
          }else{
            this.notificationMessage ="Something went wrong"
          }
            this.showNotificationBar(this.notificationMessage,"error")
            this.addNewValue=""
          }
           this.$emit("displayLoader",false);
        // if(response.data.message.trim().length == 0){


        // }
      }
    },
    showNotificationBar(message,notificationType){
      this.notificationMessage =message
      this.notificationType=notificationType
      this.showNotification = true;
      this.notificationKey = "error-default";
      this.addNewValue=""
    },
    trimText(text) {
      return text.length > 150 ? text.substring(0, 150) + "..." : text;
    }
    }
}
</script>
<style lang="scss" scoped>
  @import "../../assets/css/styles.scss";
#select-drop-down::v-deep .modal .mds-section___VueMDS3Demo .mds-section__actions___VueMDS3Demo{
  margin-left: auto !important;
  justify-content: center;
}
</style>