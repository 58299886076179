<template>
  <div>
    <mds-modal
      title="Add New Entity"
      :width="'600px'"
      v-model="toggle"
      action-required
    >
      <div style="width: 100%; position: relative">
        <div v-if="onClickshowLoader" style="width: 100%">
          <loader-component></loader-component>
        </div>
        <div style="width: 70%">
          <mds-form>
            <mds-select
              label="Entity Type"
              v-model="entityType"
              @change="onEntityTypeChange($event)"
            >
              <option value="">Select...</option>
              <option
                v-for="entityType in getEntityTypes"
                :key="entityType.entitytypeid"
                :value="entityType.entitytypeid"
              >
                {{ entityType.entitytypedescription }}
              </option>
            </mds-select>
            <div v-if="type == 'Existing'" style="margin-bottom: 16px">
              <mds-select
                v-if="(entityType) => 1"
                label="Select Existing Template"
                v-model="existingEntity"
              >
                <option value="">Select...</option>
                <option
                  v-for="entity in existingTemplateList"
                  :key="entity.entityid"
                  :value="entity.entityid"
                >
                  {{ entity.entityname }}
                </option>
              </mds-select>
            </div>
            <mds-input
              :label="inputLabel"
              v-if="inputLabel"
              :maxlength="enums.inputMaxLength"
              v-model="newAddedEntityName"
              @input="addButtonDisable()"
            ></mds-input>
            <div v-if="type == 'New'">
              <!-- selectedEntityParentList -->
              <template v-for="(parentList,index) in selectedEntityParentList">
                <mds-select
                  :key="index"
                  :label="'Parent '+parentList.entitytypedescription"
                  v-model="parentList.selectedValue"
                  :disabled="parentList.dropDownList == undefined || parentList.dropDownList.length == 0"
                  @change="onEntityChange($event,parentList)"
                >
                  <option value="">Select...</option>
                  <option
                    v-for="entity in parentList.dropDownList"
                    :key="entity.entityid"
                    :value="entity.entityid"
                  >
                    {{ entity.entityname }}
                  </option>
                </mds-select>
              </template>
            </div>
          </mds-form>
        </div>
      </div>

      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <button-component
            :onClick="onCancelClick"
            buttonName="Cancel"
            buttonVariation="secondary"
          >
          </button-component>
          <button-component
            :onClick="onAddClick"
            buttonName="Add"
            iconName="plus"
            buttonVariation="primary"
            :isDisabled="disableAddButton"
          >
          </button-component>
          <!-- <mds-button @click="toggle = !toggle" variation="secondary">Cancel</mds-button>
                    <mds-button @click="toggle = !toggle" variation="primary">Save</mds-button> -->
        </mds-button-container>
      </template>
    </mds-modal>
   <notification-component
      v-if="showNotification"
      :notificationMessage="notificationMessage"
      :notificationType="notificationType"
      :keyType="notificationKey"
      :dismissDelay="5000"
      @close="showNotification = false"
   
   >
   </notification-component>
  </div>
</template>
<script>
import MdsModal from "@mds/modal";
import MdsSelect from "@mds/select";
import MdsInput from "@mds/input";
import MdsForm from "@mds/form";
import { MdsButtonContainer } from "@mds/button";
import ButtonComponent from "../ui_component/ButtonComponent.vue";
import { mapGetters, mapActions } from "vuex";
import Enums from "../../config/Enums.json";
// import MdsFieldset from "@mds/fieldset";
// import MdsRadioButton from "@mds/radio-button";
import LoaderComponent from "../ui_component/loaderComponent.vue";
import NotificationComponent from '../ui_component/notificationComponent.vue';
// import {MdsNotification, MdsNotificationContainer} from '@mds/notification'
export default {
  components: {
    MdsModal,
    MdsSelect,
    MdsInput,
    MdsForm,
    MdsButtonContainer,
    ButtonComponent,
    LoaderComponent,
    NotificationComponent,
    // MdsFieldset,
    // MdsRadioButton,
    // MdsNotification, MdsNotificationContainer
  },

  data() {
    return {
      showNotification:false,
      enums:Enums,
      type: "New",
      disableAddButton:true,
      onClickshowLoader: false,
      notificationKey:'',
      notificationType:'',
      notificationMessage:null,
      toggle: true,
      entityType:null,
      selectedEntityParentList:[],
      newAddedEntityName: "",
      inputLabel: "",
      legalEntityList: [],
      fundEntityList: [],
      shareClassList: [],
      existingTemplateList: [],
      existingEntity: null,
      parentLegalEntity: null,
      parentEntityFund: null,
      parentEntityShareClass: null,
      selectedParentPayload: {
        entityTypeId: 2,
        parentEntityId: 18188,
      },
      addEntityPayload: {
        entitytypeid: 0,
        parententitytype: null,
        entitytype: null,
        parententities: null,
        childentities: null,
        childtypes: null,
        entityname: "",
        parententityid: 0,
        parententitytypeid: 0,
        parententityname: null,
        entityid: 0,
        iscontadd: "False",
      },
      entityDropDownPayload: {
        entityid: 0,
        parententityid: 0,
        entitytypeid: 0,
      },
    };
  },
  computed: {
    ...mapGetters("entity", [
      "getEntities",
      "getSelectedEntity",
      "getEntityTypes",
      "getParentEntityDetails",
    ]),
  },
  async mounted() {
    if (this.getEntityTypes.length === 0) {
      // set loading screen
      this.showLoader = true;
      await this.fetchEntityTypes();
      this.showLoader = false;
    }
  },
  methods: {
    ...mapActions("entity", [
      "fetchEntityTypes",
      "fetchDropdownListEntity",
      "createNewEntity",
    ]),
    radioButtonChange() {
     this.newAddedEntityName = "";
     this.existingEntity=null
      if(this.type == "Existing" && this.entityType != null){
        this.getExistingTemplates(this.entityType)
      }
    },
    clear() {
      this.newAddedEntityName = "";
      this.parentLegalEntity = null;
      this.legalEntityList = [];
      this.fundEntityList = [];
      this.shareClassList = [];
      this.parentLegalEntity = null;
      this.parentEntityFund = null;
      this.parentEntityShareClass = null;
      this.entityDropDownPayload.parententityid = 0;
    },
    async onEntityTypeChange() {
      this.clear();
      if (this.type == "Existing") {
        await this.getExistingTemplates(this.entityType);
      } else {
        if (this.entityType > 1) {
          this.onClickshowLoader = true;
          this.entityDropDownPayload.entitytypeid = 1;
          var response = await this.fetchDropdownListEntity(
            this.entityDropDownPayload
          );
          if (response.status == 200) {
            this.legalEntityList = response.data;
          }
          this.onClickshowLoader = false;
        }
      }
      if(this.entityType){
        let selectedEntityType = this.getEntityTypes.filter(entityType => entityType.entitytypeid == this.entityType)
        this.inputLabel = "Name of New "+selectedEntityType[0].entitytypedescription
        await this.checkList()
      }else{
        this.inputLabel =""
        this.selectedEntityParentList=[]
      }
      this.disableAddButton=true
      
    },
    async checkList(){
       this.selectedEntityParentList=[]
      await this.getParentDetails(this.entityType)
      this.selectedEntityParentList=JSON.parse(JSON.stringify(this.selectedEntityParentList.sort((a, b) => a.entitytypeid - b.entitytypeid)))
      for(let i=0;i<this.selectedEntityParentList.length;i++){
        if(this.selectedEntityParentList[i].entitytypeid == 1){
          this.selectedEntityParentList[i]["isDisabled"]=false
          this.selectedEntityParentList[i]["dropDownList"]=this.legalEntityList
          this.selectedEntityParentList[i]["selectedValue"]=null
        }else{
          this.selectedEntityParentList[i]["isDisabled"]=true
          this.selectedEntityParentList[i]["dropDownList"]=[]
          this.selectedEntityParentList[i]["selectedValue"]=null
        }
      }
    },
    async getParentDetails(entitytypeid){
      for(let i=0;i<this.getEntityTypes.length;i++){
        if(this.getEntityTypes[i].childentityid == entitytypeid){
          this.selectedEntityParentList.push(this.getEntityTypes[i])
          if(this.getEntityTypes[i].entitytypeid != 1){
            await this.getParentDetails(this.getEntityTypes[i].entitytypeid)
          }
        }
        
      }
    },
    async getExistingTemplates(entityType) {
      this.onClickshowLoader = true;
      this.entityDropDownPayload.entitytypeid = entityType;
      this.entityDropDownPayload.parententityid = null;
      this.entityDropDownPayload.entityid = null;
      var response = await this.fetchDropdownListEntity(
        this.entityDropDownPayload
      );
      if (response.status == 200) {
        this.existingTemplateList = response.data;
      }
      this.onClickshowLoader = false;
    },
    async onEntityChange(event,EntityTypeDetails){
       this.onClickshowLoader = true;
      this.entityDropDownPayload.parententityid = event;
      this.entityDropDownPayload.entitytypeid = EntityTypeDetails.childentityid;
        var response = await this.fetchDropdownListEntity(
          this.entityDropDownPayload
        );
       let indexOfSelectedEntityType =  this.selectedEntityParentList.findIndex(x => x.entitytypeid === EntityTypeDetails.entitytypeid);
        if (response.status == 200) {
          for(let i=0;i<this.selectedEntityParentList.length;i++){
            if(i > indexOfSelectedEntityType){
              this.$set(this.selectedEntityParentList[i], "isDisabled", false);
              this.$set(this.selectedEntityParentList[i], "dropDownList", []);
              this.$set(this.selectedEntityParentList[i], "selectedValue", null);
               
            }
            if(this.selectedEntityParentList[i].entitytypeid == EntityTypeDetails.childentityid){
               this.$set(this.selectedEntityParentList[i], "isDisabled", true);
               this.$set(this.selectedEntityParentList[i], "dropDownList", response.data);
               if(response.data.length == 0){
                 this.notificationType = "warning"
                  this.notificationMessage="No "+EntityTypeDetails.childentitytypedescription+" entities are present for selected "+EntityTypeDetails.entitytypedescription+" entity"
                  this.notificationKey = "warning-default"
                  this.showNotification = true
                 console.log("No "+EntityTypeDetails.childentitytypedescription+" entities are present for selected "+EntityTypeDetails.entitytypedescription+" entity");
               }
            }
          }
        }
        this.addButtonDisable()
         this.onClickshowLoader = false;
    },
    addButtonDisable(){
      if(this.entityType == 1 && this.newAddedEntityName.trim().length > 0){
        this.disableAddButton= false;
      }else{
        for(let i=0;i<this.selectedEntityParentList.length;i++){
          if(this.selectedEntityParentList[i].selectedValue == null){
            this.disableAddButton= true
            break;
          }else{
            if(i == this.selectedEntityParentList.length-1){
              if (this.newAddedEntityName.trim().length > 0) {
                this.disableAddButton= false;
              }else{
                this.disableAddButton= true
              }
            }
          }
        }
    }
    },
    onCancelClick() {
      this.inputLabel = "Name of New Legal Entity";
      this.entityType = "";
      this.newAddedEntityName = "";
      this.clear();
      this.$emit("clicked", false);
    },
    onAddClick() {
      this.addEntityPayload.entitytypeid = parseInt(this.entityType);
      this.addEntityPayload.entityname = this.newAddedEntityName;
      
      if (this.entityDropDownPayload.parententityid != 0) {
        this.addEntityPayload.parententityid = this.entityDropDownPayload.parententityid;
      }
      this.$emit("entityAdded", this.addEntityPayload);

      //  this.showLoader = false;
      // if(response.status == 200){
      //   this.$emit('entityAdded',response)
      // }
    },
  },
};
</script>
